import { Typography as MuiTypography, styled } from "@mui/material";

import { ColorType } from "types";

export const TYPOGRAPHY_COMMON_STYLES = {
  fontWeightSemiBold: 600,
  body1: {
    fontSize: 16,
    lineHeight: "24px",
  },
  body2: {
    fontSize: 14,
    lineHeight: "20px",
  },
  label: {
    getLabelStyle({ fontSize }: { fontSize: number }) {
      return {
        fontSize,
        lineHeight: "18px",
      };
    },
  },
};

// ![AW] as typeof MuiTypography - required too support component prop for Typography

const Typography = styled(MuiTypography)(({ theme, color }) => ({
  color: theme.palette[color as ColorType]
    ? theme.palette[color as ColorType].main
    : (color as string),
})) as typeof MuiTypography;

export const H1 = styled(Typography)(({ theme }) => ({
  fontSize: 48,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: "56px",
})) as typeof MuiTypography;

export const H2 = styled(Typography)(({ theme }) => ({
  fontSize: 36,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: "44px",
})) as typeof MuiTypography;

export const H3Bold = styled(Typography)(({ theme }) => ({
  fontSize: 32,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: "40px",
})) as typeof MuiTypography;

export const H3Regular = styled(Typography)(({ theme }) => ({
  fontSize: 32,
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: "44px",
}));

export const H4 = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: "32px",
})) as typeof MuiTypography;

export const H5Bold = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: "28px",
})) as typeof MuiTypography;

export const H5Medium = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: "28px",
}));

export const H6 = styled(Typography)({
  fontSize: 18,
  fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
  lineHeight: "28px",
}) as typeof MuiTypography;

export const Body1Regular = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  ...TYPOGRAPHY_COMMON_STYLES.body1,
})) as typeof MuiTypography;

export const Body1SemiBold = styled(Typography)({
  fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
  ...TYPOGRAPHY_COMMON_STYLES.body1,
}) as typeof MuiTypography;

export const Body2Regular = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  ...TYPOGRAPHY_COMMON_STYLES.body2,
})) as typeof MuiTypography;

export const Body2SemiBold = styled(Typography)({
  fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
  ...TYPOGRAPHY_COMMON_STYLES.body2,
}) as typeof MuiTypography;

export const Label18SemiBold = styled(Typography)({
  fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
  fontSize: 18,
  lineHeight: "24px",
}) as typeof MuiTypography;

export const PrimaryLabel = styled(Typography)({
  fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
  fontSize: 14,
  lineHeight: "18px",
  color: '#90959E',
}) as typeof MuiTypography;

export const Label16Regular = Body1Regular;

export const Label16Medium = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  ...TYPOGRAPHY_COMMON_STYLES.label.getLabelStyle({ fontSize: 16 }),
})) as typeof MuiTypography;

export const Label16SemiBold = styled(Typography)({
  fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
  ...TYPOGRAPHY_COMMON_STYLES.label.getLabelStyle({ fontSize: 16 }),
}) as typeof MuiTypography;

export const Label14Medium = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  ...TYPOGRAPHY_COMMON_STYLES.label.getLabelStyle({ fontSize: 14 }),
})) as typeof MuiTypography;

export const Label14SemiBold = styled(Typography)({
  fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
  ...TYPOGRAPHY_COMMON_STYLES.label.getLabelStyle({ fontSize: 14 }),
}) as typeof MuiTypography;

export const Label12Regular = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  ...TYPOGRAPHY_COMMON_STYLES.label.getLabelStyle({ fontSize: 12 }),
})) as typeof MuiTypography;

export const Label12SemiBold = styled(Typography)({
  fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
  ...TYPOGRAPHY_COMMON_STYLES.label.getLabelStyle({ fontSize: 12 }),
}) as typeof MuiTypography;

export const Label12Bold = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  ...TYPOGRAPHY_COMMON_STYLES.label.getLabelStyle({ fontSize: 12 }),
})) as typeof MuiTypography;

export const Label10SemiBold = styled(Typography)({
  fontSize: 10,
  fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
  lineHeight: "16px",
}) as typeof MuiTypography;

export const Label10Regular = styled(Typography)({
  fontSize: 10,
  lineHeight: "16px",
}) as typeof MuiTypography;
