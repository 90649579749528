/* eslint-disable */
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  EmissionsOnMonthBody,
  getConsumptionsByFacilityType,
  getConsumptionsByRegion,
  getConsumptionsBySite,
  getConsumptionsOnMonth,
  getConsumptionsYearly,
  getCountriesConsumptionData,
  getCountriesEmissionsData,
  getCountriesFinacialsData,
  getEmissionsByFacilityType,
  getEmissionsByRegion,
  getEmissionsByScope,
  getEmissionsBySite,
  getEmissionsByUtilityType,
  getEmissionsOnMonth,
  getEmissionsScopeYearly,
  getEmissionsYearly,
  getEstimatesBySite,
  getEstimatesOnMonth,
  getFinancialEmissionSpendData,
  getFinancialsByFacilityType,
  getFinancialsByRegion,
  getFinancialsBySite,
  getFinancialsByUtilityType,
  getFinancialsOnMonth,
  getFinancialsYearly,
  getScopeOnMonth,
  getSiteSpecificConsumptionData,
  getSiteSpecificEmissionsData,
  getSpendAccData,
  getUnitLevelCostData,
} from "api/dashboards";

import {
  AutocompleteField,
  COLORS,
  FILTERS_AND_ACTIONS_WRAPPER,
  FLEX_ALIGN_CENTER_GAP_2, // MarginWrapper,
  MultiSelectControlledOption,
  MultiselectControlledField, // Tabs,
  usePagination,
} from "components";
import { SelectControlledField } from "components/inputs/select-field";

import {
  useAccountNumbersMultiselect,
  useCountriesSelect,
  useCountryRegionsSelect,
  useFacilityTypesSelect,
  useGetConfigParameter,
  useMeterNumbersMultiselect,
  useSitesMultiselect,
  useUtilityBillsYears,
  useUtilityTypesMultiselect,
} from "hooks";
import { useFilteredSitesMultiselect } from "hooks/multiselect/filteredSites";
import { useScopeSelect } from "hooks/singleselect/scope";

import { LOCAL_STORAGE_KEYS, QUERY_PARAMS_KEYS } from "consts";

import { ConsumptionElectricityComponent } from "./components/consumptionElectricity";
import { ConsumptionNaturalGasComponent } from "./components/consumptionNaturalGas";
import { ConsumptionSewerComponent } from "./components/consumptionSewer";
import { ConsumptionWaterComponent } from "./components/consumptionWater";
import { DashboardTabs } from "./components/dashboardTabs";
import { OverallEmissionsComponent } from "./components/overallEmissions";
import { OverallFinancialsComponent } from "./components/overallFinancials";
import { SiteSpecificConsumption } from "./components/siteSpecificConsumption";
import { SiteSpecificEmissions } from "./components/siteSpecificEmissions";
import { DASHBOARD_PAGE } from "./consts";
import { useChartsDataStateHook } from "./hooks/useChartsDataStateHook";
import { useCustomYearsDropdownHook } from "./hooks/useCustomYearsDropdownHook";
import { filterDataWithFiscalYear } from "./utils";

const MULTISELECT_WRAPPER = { minWidth: "220px", width: "fit-content" };
const PageDiv = styled("div")(() => ({
  maxWidth: "calc(100% - 50px)",
}));
const checkboxIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const btnStates = {
  GHC_EMISSIONS: "ghc-emission",
  CONSUMPTION: "consumption",
  FINANCIAL: "financial",
  SITE_SPECIFIC: "site-specific",
};

export const DashboardPage = () => {
  const { setInitialPage, updateQueryParamsWithInitialPage } = usePagination({
    pageSizeLocalStorageKey: LOCAL_STORAGE_KEYS.DOCUMENT_SUMMARY_PAGE_SIZE,
  });

  const { GHC_EMISSIONS, CONSUMPTION, FINANCIAL, SITE_SPECIFIC } = btnStates;

  const { data: availableYears } = useUtilityBillsYears();

  const { data } = useGetConfigParameter();

  const startMonth =
    data && data.find((obj) => obj.name === "company_month_start")?.configValue;
  const endMonth =
    data && data.find((obj) => obj.name === "company_month_end")?.configValue;

  const currentYear = new Date().getFullYear() - 1;
  const currentFiscalYear = "FY" + currentYear;

  const nextYear = new Date().getFullYear() + 1;

  const fiscalYears = availableYears && [
    ...availableYears.map((yearItem: any) => `FY${yearItem}`),
    `FY${nextYear}`,
  ];

  const [fiscalYear, setFiscalYear] = useState(currentFiscalYear);
  const [yearValue, setYearValue] = useState<any>(null);

  useEffect(() => {
    const yearNumber = parseInt(fiscalYear.replace("FY", ""), 10) as number;
    setYearValue(yearNumber);
  }, []);

  useEffect(() => {
    const yearNumber = parseInt(fiscalYear.replace("FY", ""), 10) as number;
    setYearValue(yearNumber);
  }, [fiscalYear]);

  const [searchParams] = useSearchParams();
  // setSearchParams

  const {
    isCountryRegionsLoading,
    selectedCountryRegion,
    setSelectedCountryRegion,
    countryRegionsList,
  } = useCountryRegionsSelect({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    withEmptyOption: false,
  });

  const {
    isCountriesLoading,
    selectedCountry,
    setSelectedCountry,
    countriesList,
  } = useCountriesSelect({
    selectedCountryRegion,
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    withEmptyOption: false,
  });

  const {
    isFacilityTypesLoading,
    setSelectedFalityTypeOption,
    selectedFacilityType,
    facilityTypesList,
  } = useFacilityTypesSelect({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    withEmptyOption: false,
  });

  const { isScopesLoading, setSelectedScope, selectedScope, scopesList } =
    useScopeSelect({
      updateExternalStates: setInitialPage,
      getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
      withEmptyOption: false,
    });

  const { isSitesLoading, selectedSites, setSelectedSites, sitesOptions } =
    useFilteredSitesMultiselect({
      selectedCountry,
      updateExternalStates: setInitialPage,
      getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    });

  const {
    isAccountNumbersLoading,
    selectedAccountNumbers,
    setSelectedAccountNumbers,
    accountNumbersOptions,
  } = useAccountNumbersMultiselect({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    withEmptyOption: true,
  });

  const {
    isMeterNumbersLoading,
    selectedMeterNumbers,
    setSelectedMeterNumbers,
    meterNumbersOptions,
  } = useMeterNumbersMultiselect({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    withEmptyOption: true,
  });

  const {
    isUtilityTypesLoading,
    selectedUtilityTypes,
    setSelectedUtilityTypes,
    utilityTypesOptions,
  } = useUtilityTypesMultiselect({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    withEmptyOption: true,
  });

  useEffect(() => {
    setSelectedCountry("");
  }, [countryRegionsList,selectedCountryRegion]);

  useEffect(() => {
    setSelectedSites([]);
  }, [countriesList,selectedCountry]);

  const filterScopeList = scopesList.filter((item) => item.includes("scope"));

  const filterTypes = utilityTypesOptions.filter((item) => item.label !== "-");
  const utitlityTypesForEmissions = utilityTypesOptions.filter(
    (item: any) => item?.emissions && item.label !== "-",
  );
  const utitlityTypesForConsumption = utilityTypesOptions.filter(
    (item: any) => item?.consumptions && item.label !== "-",
  );
  const utitlityTypesForFinancial = utilityTypesOptions.filter(
    (item: any) => item?.financials && item.label !== "-",
  );

  const filterAccountNumbers = accountNumbersOptions.filter(
    (item) => item.label !== "-",
  );
  const filterMeterNumbers = meterNumbersOptions.filter(
    (item) => item.label !== "-",
  );

  const {
    selectedYear: monthEmissionsYear,
    setSelectedYear: setMonthEmissionsYear,
    yearsList: monthEmYears,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: regionEmissionsYear,
    setSelectedYear: setRegionEmissionsYear,
    yearsList: regionEmYears,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: monthFinancialYear,
    setSelectedYear: setMonthFinancialYear,
    yearsList: monthFnYears,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: financialCarbonYear,
    setSelectedYear: setFinancialCarbonYear,
    yearsList: financialCarbonYearsList,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: monthConsumptionYear,
    setSelectedYear: setMonthConsumptionYear,
    yearsList: monthCSYears,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: monthCSWaterYear,
    setSelectedYear: setMonthCSWaterYear,
    yearsList: monthCSWaterYears,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: monthCSSewerYear,
    setSelectedYear: setMonthCSSewerYear,
    yearsList: monthCSSewerYears,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: monthCSElectricityYear,
    setSelectedYear: setMonthCSElectricityYear,
    yearsList: monthCSElectricityYears,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: scopeYear,
    setSelectedYear: setScopeYear,
    // yearsList: scopeYearsList,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: utYear,
    setSelectedYear: setUTYear,
    // yearsList: utYearsList,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: siteEmYear,
    setSelectedYear: setSiteEMyear,
    yearsList: siteEMYearsList,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: siteCostYear,
    setSelectedYear: setSiteCostYear,
    yearsList: siteCostYearsList,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: siteElecYear,
    setSelectedYear: setSiteElecYear,
    yearsList: siteElecYearsList,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: siteNGYear,
    setSelectedYear: setSiteNGYear,
    yearsList: siteNGYearsList,
  } = useCustomYearsDropdownHook({});

  const [selectedBtnOption, setSelectedBtnOption] = useState(GHC_EMISSIONS);

  const {
    monthlyScopeData,
    setMonthlyScopeData,
    yearlyScopeData,
    setYearlyScopeData,
    monthlyData,
    setMonthlyData,
    sitesData,
    setSitesData,
    yearlyData,
    setYearlyData,
    regionsData,
    setRegionsData,
    utilityData,
    setUtilityData,
    facilityTypesData,
    setFacilityTypesData,
    scopesResp,
    setScopesResp,
    countriesResp,
    setCountriesResp,

    siteSpecificAccData,
    setSiteSpecificAccData,
    siteSpecificConsumptionData,
    setSiteSpecificConsumptionData,
    setSiteSpendAccData,
    siteSpendAccData,
    monthlyFinancialData,
    setMonthlyFinancialData,
    sitesFinancialData,
    setSitesFinancialData,
    yearlyFinancialData,
    setYearlyFinancialData,
    regionsFinancialData,
    setRegionsFinancialData,
    utilityFinancialData,
    setUtilityFinancialData,
    facilityTypesFinancialData,
    setFacilityTypesFinancialData,
    countriesFinancialResp,
    setCountriesFinancialResp,
    unitLevelCost,
    setUnitLevelCost,
    financialEMSpend,
    setFinancialEMSpend,

    consumptionMonthlyData,
    setConsumptionMonthlyData,
    consumptionSitesData,
    setConsumptionSitesData,
    consumptionYearlyData,
    setConsumptionYearlyData,
    consumptionCountriesResp,
    setConsumptionCountriesResp,
    consumptionFacilityTypesData,
    setConsumptionFacilityTypesData,
    consumptionRegionsData,
    setConsumptionRegionsData,
    estimatesMonthlyData,
    setEstimatesMonthlyData,
    estimatesBySiteData,
    setEstimatesBySiteData,
  } = useChartsDataStateHook();
  // isLoading: isEmissionsOnMonthlyLoading

  const { mutateAsync: getEmissionsOnMonthly } =
    useMutation(getEmissionsOnMonth);

  const { mutateAsync: getScopeOnMonthly } = useMutation(getScopeOnMonth);

  const { mutateAsync: getEstimatesOnMonthly } =
    useMutation(getEstimatesOnMonth);
  const { mutateAsync: getEstimatesSite } = useMutation(getEstimatesBySite);

  const { mutateAsync: getEmissionsSite } = useMutation(getEmissionsBySite);

  const { mutateAsync: getEmissionsUtility } = useMutation(
    getEmissionsByUtilityType,
  );

  const { mutateAsync: getEmissionsScope } = useMutation(getEmissionsByScope);

  const { mutateAsync: getEmissionsYear } = useMutation(getEmissionsYearly);

  const { mutateAsync: getEmissionsScopeYear } = useMutation(
    getEmissionsScopeYearly,
  );

  const { mutateAsync: getEmissionsRegion } = useMutation(getEmissionsByRegion);

  const { mutateAsync: getEmissionsFacilityType } = useMutation(
    getEmissionsByFacilityType,
  );

  const { mutateAsync: getEmissionsByCountries } = useMutation(
    getCountriesEmissionsData,
  );

  const { mutateAsync: getFinancialsOnMonthly } =
    useMutation(getFinancialsOnMonth);

  const { mutateAsync: getFinancialsSite } = useMutation(getFinancialsBySite);

  const { mutateAsync: getFinancialsUtility } = useMutation(
    getFinancialsByUtilityType,
  );

  const { mutateAsync: getFinancialsYear } = useMutation(getFinancialsYearly);

  const { mutateAsync: getFinancialsRegion } = useMutation(
    getFinancialsByRegion,
  );

  const { mutateAsync: getFinancialsFacilityType } = useMutation(
    getFinancialsByFacilityType,
  );

  const { mutateAsync: getFinancialsByCountries } = useMutation(
    getCountriesFinacialsData,
  );

  const { mutateAsync: getUnitLevelCost } = useMutation(getUnitLevelCostData);

  const { mutateAsync: getFinancialEMSpend } = useMutation(
    getFinancialEmissionSpendData,
  );

  const { mutateAsync: getSSEmissionsData } = useMutation(
    getSiteSpecificEmissionsData,
  );
  const { mutateAsync: getSSConsumptionData } = useMutation(
    getSiteSpecificConsumptionData,
  );
  const { mutateAsync: getSSpendAccData } = useMutation(getSpendAccData);

  const { mutateAsync: getConsumptionOnMonthly } = useMutation(
    getConsumptionsOnMonth,
  );
  const { mutateAsync: getConsumptionSite } = useMutation(
    getConsumptionsBySite,
  );
  const { mutateAsync: getConsumptionYear } = useMutation(
    getConsumptionsYearly,
  );
  const { mutateAsync: getConsumptionRegion } = useMutation(
    getConsumptionsByRegion,
  );
  const { mutateAsync: getConsumptionFacilityType } = useMutation(
    getConsumptionsByFacilityType,
  );
  const { mutateAsync: getConsumptionByCountries } = useMutation(
    getCountriesConsumptionData,
  );

  const makeFilterApiCalls = (body: EmissionsOnMonthBody) => {
    getEmissionsOnMonthly(body, {
      onSuccess: (data) => {
        const filterData: any = filterDataWithFiscalYear(
          data,
          yearValue === null
            ? (parseInt(fiscalYear.replace("FY", ""), 10) as number)
            : yearValue,
          startMonth ? startMonth : "Jan",
          endMonth ? endMonth : "Dec",
        );
        setMonthlyData(filterData);
      },
      onError: () => {
        // console.log("failed",err)
      },
    });

    getScopeOnMonthly(body, {
      onSuccess: (data) => {
        const filterData: any = filterDataWithFiscalYear(
          data,
          yearValue === null
            ? (parseInt(fiscalYear.replace("FY", ""), 10) as number)
            : yearValue,
          startMonth ? startMonth : "Jan",
          endMonth ? endMonth : "Dec",
        );
        setMonthlyScopeData(filterData);
      },
      onError: () => {
        // console.log("failed",err)
      },
    });
    getEstimatesOnMonthly(body, {
      onSuccess: (data) => {
        const filterData: any = filterDataWithFiscalYear(
          data,
          yearValue === null
            ? (parseInt(fiscalYear.replace("FY", ""), 10) as number)
            : yearValue,
          startMonth ? startMonth : "Jan",
          endMonth ? endMonth : "Dec",
        );
        setEstimatesMonthlyData(filterData);
      },
      onError: () => {
        // console.log("failed",err)
      },
    });
    getEstimatesSite(body, {
      onSuccess: (data) => {
        const filterData: any = filterDataWithFiscalYear(
          data,
          yearValue === null
            ? (parseInt(fiscalYear.replace("FY", ""), 10) as number)
            : yearValue,
          startMonth ? startMonth : "Jan",
          endMonth ? endMonth : "Dec",
        );
        setEstimatesBySiteData(filterData);
      },
      onError: () => {
        // console.log("failed",err)
      },
    });

    getEmissionsSite(body, {
      onSuccess: (data) => {
        setSitesData(data);
      },
      onError: () => {},
    });

    getEmissionsScope(body, {
      onSuccess: (data) => {
        setScopesResp(data);
      },
      onError: () => {},
    });

    getEmissionsUtility(body, {
      onSuccess: (data) => {
        setUtilityData(data);
      },
      onError: () => {},
    });

    getEmissionsYear(body, {
      onSuccess: (data) => {
        setYearlyData(data);
      },
      onError: () => {},
    });

    getEmissionsScopeYear(body, {
      onSuccess: (data) => {
        setYearlyScopeData(data);
      },
      onError: () => {},
    });

    getEmissionsRegion(body, {
      onSuccess: (data) => {
        const filterData: any = filterDataWithFiscalYear(
          data,
          yearValue === null
            ? (parseInt(fiscalYear.replace("FY", ""), 10) as number)
            : yearValue,
          startMonth ? startMonth : "Jan",
          endMonth ? endMonth : "Dec",
        );
        setRegionsData(filterData);
      },
      onError: () => {},
    });

    getEmissionsFacilityType(body, {
      onSuccess: (data) => {
        setFacilityTypesData(data);
      },
      onError: () => {},
    });

    getEmissionsByCountries(body, {
      onSuccess: (data) => {
        setCountriesResp(data);
      },
      onError: () => {},
    });

    getFinancialsOnMonthly(body, {
      onSuccess: (data) => {
        const filterData: any = filterDataWithFiscalYear(
          data,
          yearValue === null
            ? (parseInt(fiscalYear.replace("FY", ""), 10) as number)
            : yearValue,
          startMonth ? startMonth : "Jan",
          endMonth ? endMonth : "Dec",
        );
        setMonthlyFinancialData(filterData);
      },
      onError: () => {},
    });

    getFinancialsSite(body, {
      onSuccess: (data) => {
        setSitesFinancialData(data);
      },
      onError: () => {},
    });

    getFinancialsUtility(body, {
      onSuccess: (data) => {
        setUtilityFinancialData(data);
      },
      onError: () => {},
    });

    getFinancialsYear(body, {
      onSuccess: (data) => {
        setYearlyFinancialData(data);
      },
      onError: () => {},
    });

    getFinancialsRegion(body, {
      onSuccess: (data) => {
        setRegionsFinancialData(data);
      },
      onError: () => {},
    });

    getFinancialsFacilityType(body, {
      onSuccess: (data) => {
        setFacilityTypesFinancialData(data);
      },
      onError: () => {},
    });

    getFinancialsByCountries(body, {
      onSuccess: (data) => {
        setCountriesFinancialResp(data);
      },
      onError: () => {},
    });

    getUnitLevelCost(body, {
      onSuccess: (data) => {
        setUnitLevelCost(data);
      },
      onError: () => {},
    });

    getFinancialEMSpend(body, {
      onSuccess: (data) => {
        const filterData: any = filterDataWithFiscalYear(
          data,
          yearValue === null
            ? (parseInt(fiscalYear.replace("FY", ""), 10) as number)
            : yearValue,
          startMonth ? startMonth : "Jan",
          endMonth ? endMonth : "Dec",
        );
        setFinancialEMSpend(filterData);
      },
      onError: () => {},
    });

    getSSEmissionsData(body, {
      onSuccess: (data) => {
        setSiteSpecificAccData(data);
      },
      onError: () => {},
    });

    getSSConsumptionData(body, {
      onSuccess: (data) => {
        setSiteSpecificConsumptionData(data);
      },
      onError: () => {},
    });

    getSSpendAccData(body, {
      onSuccess: (data) => {
        setSiteSpendAccData(data);
      },
      onError: () => {},
    });

    getConsumptionOnMonthly(body, {
      onSuccess: (data) => {
        const filterData: any = filterDataWithFiscalYear(
          data,
          yearValue === null
            ? (parseInt(fiscalYear.replace("FY", ""), 10) as number)
            : yearValue,
          startMonth ? startMonth : "Jan",
          endMonth ? endMonth : "Dec",
        );
        setConsumptionMonthlyData(filterData);
      },
      onError: () => {
        // console.log("failed",err)
      },
    });

    getConsumptionSite(body, {
      onSuccess: (data) => {
        setConsumptionSitesData(data);
      },
      onError: () => {},
    });

    getConsumptionYear(body, {
      onSuccess: (data) => {
        setConsumptionYearlyData(data);
      },
      onError: () => {},
    });

    getConsumptionRegion(body, {
      onSuccess: (data) => {
        setConsumptionRegionsData(data);
      },
      onError: () => {},
    });

    getConsumptionFacilityType(body, {
      onSuccess: (data) => {
        setConsumptionFacilityTypesData(data);
      },
      onError: () => {},
    });

    getConsumptionByCountries(body, {
      onSuccess: (data) => {
        setConsumptionCountriesResp(data);
      },
      onError: () => {},
    });
  };

  useEffect(() => {
    if (
      isSitesLoading ||
      isAccountNumbersLoading ||
      isCountriesLoading ||
      isCountryRegionsLoading ||
      isFacilityTypesLoading ||
      isScopesLoading ||
      isUtilityTypesLoading ||
      isMeterNumbersLoading
    ) {
      return;
    }

    let meterNum;
    let accNum;
    let utilityType;
    let siteid;
    let counReg;
    let country;
    let facilityType;
    let scope;

    if (sitesOptions) {
      const selectedOptionsFromSearchParams = searchParams.getAll(
        QUERY_PARAMS_KEYS.SITE_IDS,
      );

      if (selectedOptionsFromSearchParams.length) {
        siteid = sitesOptions
          .filter(({ value }) =>
            selectedOptionsFromSearchParams.includes(`${value}`),
          )
          .map((option) => option.value);
      }
    }
    if (accountNumbersOptions) {
      const selectedOptionsFromSearchParams = searchParams.getAll(
        QUERY_PARAMS_KEYS.ACCOUNT_NUMBERS,
      );

      if (selectedOptionsFromSearchParams.length) {
        accNum = accountNumbersOptions
          .filter(({ value }) =>
            selectedOptionsFromSearchParams.includes(`${value}`),
          )
          .map((option) => option.label);
      }
    }
    if (meterNumbersOptions) {
      const selectedOptionsFromSearchParams = searchParams.getAll(
        QUERY_PARAMS_KEYS.METER_NUMBERS,
      );

      if (selectedOptionsFromSearchParams.length) {
        meterNum = meterNumbersOptions
          .filter(({ value }) =>
            selectedOptionsFromSearchParams.includes(`${value}`),
          )
          .map((option) => option.label);
      }
    }
    if (utilityTypesOptions) {
      const selectedOptionsFromSearchParams = searchParams.getAll(
        QUERY_PARAMS_KEYS.UTILITY_TYPE_IDS,
      );

      if (selectedOptionsFromSearchParams.length) {
        utilityType = utilityTypesOptions
          .filter(({ value }) =>
            selectedOptionsFromSearchParams.includes(`${value}`),
          )
          .map((option) => option.value);
      }
    }

    if (countryRegionsList) {
      const selectedOptionsFromSearchParams = searchParams.getAll(
        QUERY_PARAMS_KEYS.COUNTRY_REGION,
      );

      if (selectedOptionsFromSearchParams.length) {
        counReg = countryRegionsList.find((value) =>
          selectedOptionsFromSearchParams.includes(value),
        );
      }
    }

    if (countriesList) {
      const selectedOptionsFromSearchParams = searchParams.getAll(
        QUERY_PARAMS_KEYS.COUNTRIES,
      );

      if (selectedOptionsFromSearchParams.length) {
        country = countriesList.find((value) =>
          selectedOptionsFromSearchParams.includes(value),
        );
      }
    }

    if (scopesList) {
      const selectedOptionsFromSearchParams = searchParams.getAll(
        QUERY_PARAMS_KEYS.SCOPE,
      );

      if (selectedOptionsFromSearchParams.length) {
        scope = scopesList.find((value) =>
          selectedOptionsFromSearchParams.includes(value),
        );
      }
    }
    if (facilityTypesList) {
      const selectedOptionsFromSearchParams = searchParams.getAll(
        QUERY_PARAMS_KEYS.SITE_TYPES,
      );

      if (selectedOptionsFromSearchParams.length) {
        facilityType = facilityTypesList.find((value) =>
          selectedOptionsFromSearchParams.includes(value),
        );
      }
    }
    // const body: EmissionsOnMonthBody = {
    //   countryRegion: counReg || "AMER",
    //   countries: null,
    //   siteIds: [1,2,3],
    //   facilityTypes: ["FACTORY","AUDITORIUM"],
    //   scope: scope || "",
    //   utilityTypeIds: null,
    //   accountNumbers: null,
    //   meterNumbers: null,
    // };
    const body: EmissionsOnMonthBody = {
      countryRegion: counReg || "", // || "AMER",
      countries: country ? [country] : null,
      siteIds: siteid && siteid.length ? siteid : null,
      facilityTypes: facilityType ? [facilityType] : null,
      scope: scope || "",
      utilityTypeIds: utilityType && utilityType?.length ? utilityType : null,
      accountNumbers: accNum && accNum.length ? accNum : null,
      meterNumbers: meterNum && meterNum.length ? meterNum : null,
    };

    makeFilterApiCalls(body);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSitesLoading,
    isCountryRegionsLoading,
    isCountriesLoading,
    isFacilityTypesLoading,
    isScopesLoading,
    isUtilityTypesLoading,
    isAccountNumbersLoading,
    isMeterNumbersLoading,
    fiscalYear,
  ]);

  const onClearFiltersClick = () => {
    setSelectedCountryRegion("");
    setSelectedCountry("");
    setSelectedFalityTypeOption("");
    setSelectedScope("");
    setSelectedUtilityTypes([]);
    setSelectedSites([]);
    setSelectedAccountNumbers([]);
    setSelectedMeterNumbers([]);
    setFiscalYear(currentFiscalYear);
    getDAta();
  };

  const getDAta = () => {
    const body: EmissionsOnMonthBody = {
      countryRegion: "",
      countries: null,
      siteIds: null,
      facilityTypes: null,
      scope: "",
      utilityTypeIds: null,
      accountNumbers: null,
      meterNumbers: null,
    };
    makeFilterApiCalls(body);

    // window.location.href =  DASHBOARD_PAGE.ABSOLUTE_PATH;
    const newUrl = DASHBOARD_PAGE.ABSOLUTE_PATH;
    window.history.pushState({}, "", newUrl);
  };
  const onApplyFiltersClick = () => {
    const body: EmissionsOnMonthBody = {
      countryRegion: selectedCountryRegion, // || "AMER",
      countries: selectedCountry ? [selectedCountry] : null,
      siteIds: selectedSites.length ? selectedSites.map((e) => e.value) : null,
      facilityTypes: selectedFacilityType ? [selectedFacilityType] : null,
      scope: selectedScope || "",
      utilityTypeIds: selectedUtilityTypes.length
        ? selectedUtilityTypes.map((e) => e.value)
        : null,
      accountNumbers: selectedAccountNumbers.length
        ? selectedAccountNumbers.map((e) => e.label)
        : null,
      meterNumbers: selectedMeterNumbers.length
        ? selectedMeterNumbers.map((e) => e.label)
        : null,
    };
    makeFilterApiCalls(body);
  };

  const tabs = {
    [GHC_EMISSIONS]: [
      {
        label: "Overall Emissions",
        content: (
          <OverallEmissionsComponent
            estimatesBySiteData={estimatesBySiteData}
            estimatesMonthlyData={estimatesMonthlyData}
            monthlyScopeData={monthlyScopeData}
            monthlyData={monthlyData}
            sitesData={sitesData}
            yearlyData={yearlyData}
            yearlyScopeData={yearlyScopeData}
            regionsData={regionsData}
            utilityData={utilityData}
            scopesResp={scopesResp}
            facilityTypesData={facilityTypesData}
            countriesResp={countriesResp}
            sitesOptions={sitesOptions}
            countryRegionsList={countryRegionsList}
            facilityTypesList={facilityTypesList}
            utilityTypesOptions={utilityTypesOptions}
            monthEmYears={monthEmYears}
            regionEmYears={regionEmYears}
            monthEmissionsYear={monthEmissionsYear}
            setMonthEmissionsYear={setMonthEmissionsYear}
            regionEmissionsYear={regionEmissionsYear}
            setRegionEmissionsYear={setRegionEmissionsYear}
            // scopeYearsList={scopeYearsList}
            scopeYear={scopeYear}
            setScopeYear={setScopeYear}
            // utYearsList={utYearsList}
            utYear={utYear}
            setUTYear={setUTYear}
            startMonth={startMonth ? startMonth : " Jan"}
            endMonth={endMonth ? endMonth : "Dec"}
            selectedYear={yearValue}
          />
        ),
      },
    ],
    [CONSUMPTION]: [
      {
        label: "Electricity",
        content: (
          <ConsumptionElectricityComponent
            monthlyData={consumptionMonthlyData}
            sitesData={consumptionSitesData}
            yearlyData={consumptionYearlyData}
            regionsData={consumptionRegionsData}
            facilityTypesData={consumptionFacilityTypesData}
            countriesResp={consumptionCountriesResp}
            sitesOptions={sitesOptions}
            countryRegionsList={countryRegionsList}
            facilityTypesList={facilityTypesList}
            monthConsumptionYear={monthCSElectricityYear}
            setMonthConsumptionYear={setMonthCSElectricityYear}
            startMonth={startMonth ? startMonth : " Jan"}
            endMonth={endMonth ? endMonth : "Dec"}
            selectedYear={yearValue}
          />
        ),
      },
      {
        label: "Natural Gas",
        content: (
          <ConsumptionNaturalGasComponent
            monthlyData={consumptionMonthlyData}
            sitesData={consumptionSitesData}
            yearlyData={consumptionYearlyData}
            regionsData={consumptionRegionsData}
            facilityTypesData={consumptionFacilityTypesData}
            countriesResp={consumptionCountriesResp}
            sitesOptions={sitesOptions}
            countryRegionsList={countryRegionsList}
            facilityTypesList={facilityTypesList}
            monthConsumptionYear={monthConsumptionYear}
            setMonthConsumptionYear={setMonthConsumptionYear}
            startMonth={startMonth ? startMonth : " Jan"}
            endMonth={endMonth ? endMonth : "Dec"}
            selectedYear={yearValue}
          />
        ),
      },
      {
        label: "Water",
        content: (
          <ConsumptionWaterComponent
            monthlyData={consumptionMonthlyData}
            sitesData={consumptionSitesData}
            yearlyData={consumptionYearlyData}
            regionsData={consumptionRegionsData}
            facilityTypesData={consumptionFacilityTypesData}
            countriesResp={consumptionCountriesResp}
            sitesOptions={sitesOptions}
            countryRegionsList={countryRegionsList}
            facilityTypesList={facilityTypesList}
            monthConsumptionYear={monthCSWaterYear}
            setMonthConsumptionYear={setMonthCSWaterYear}
            startMonth={startMonth ? startMonth : " Jan"}
            endMonth={endMonth ? endMonth : "Dec"}
            selectedYear={yearValue}
          />
        ),
      },
      {
        label: "Sewer",
        content: (
          <ConsumptionSewerComponent
            monthlyData={consumptionMonthlyData}
            sitesData={consumptionSitesData}
            yearlyData={consumptionYearlyData}
            regionsData={consumptionRegionsData}
            facilityTypesData={consumptionFacilityTypesData}
            countriesResp={consumptionCountriesResp}
            sitesOptions={sitesOptions}
            countryRegionsList={countryRegionsList}
            facilityTypesList={facilityTypesList}
            monthConsumptionYear={monthCSSewerYear}
            setMonthConsumptionYear={setMonthCSSewerYear}
            startMonth={startMonth ? startMonth : " Jan"}
            endMonth={endMonth ? endMonth : "Dec"}
            selectedYear={yearValue}
          />
        ),
      },
    ],
    [FINANCIAL]: [
      {
        label: "Overall Financials",
        content: (
          <OverallFinancialsComponent
            monthlyData={monthlyFinancialData}
            sitesData={sitesFinancialData}
            yearlyData={yearlyFinancialData}
            regionsData={regionsFinancialData}
            utilityData={utilityFinancialData}
            facilityTypesData={facilityTypesFinancialData}
            unitLevelCostData={unitLevelCost}
            EMSpendData={financialEMSpend}
            countriesResp={countriesFinancialResp}
            sitesOptions={sitesOptions}
            countryRegionsList={countryRegionsList}
            facilityTypesList={facilityTypesList}
            utilityTypesOptions={utilityTypesOptions}
            monthFinancialYear={monthFinancialYear}
            setMonthFinancialYear={setMonthFinancialYear}
            financialCarbonYear={financialCarbonYear}
            setFinancialCarbonYear={setFinancialCarbonYear}
            startMonth={startMonth ? startMonth : " Jan"}
            endMonth={endMonth ? endMonth : "Dec"}
            selectedYear={yearValue}
          />
        ),
      },
    ],
    [SITE_SPECIFIC]: [
      {
        label: "Emissions",
        content: (
          <SiteSpecificEmissions
            siteSpecificAccData={siteSpecificAccData}
            siteSpendAccData={siteSpendAccData}
            siteCostYear={siteCostYear}
            siteCostYearsList={siteCostYearsList}
            setSiteCostYear={setSiteCostYear}
            siteEMYearsList={siteEMYearsList}
            siteEmYear={siteEmYear}
            setSiteEMyear={setSiteEMyear}
          />
        ),
      },
      {
        label: "Consumption",
        content: (
          <SiteSpecificConsumption
            siteSpecificConsumptionData={siteSpecificConsumptionData}
            setSiteElecYear={setSiteElecYear}
            siteElecYear={siteElecYear}
            siteElecYearsList={siteElecYearsList}
            setSiteNGYear={setSiteNGYear}
            siteNGYear={siteNGYear}
            siteNGYearsList={siteNGYearsList}
          />
        ),
      },
    ],
  };

  const btnStyles = {
    fontSize: "1rem",
    color: "black",
    backgroundColor: "transparent",
  };

  const onStackButonsClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const key = (e?.target as HTMLElement)?.dataset?.key as string;
      if (key) {
        setSelectedBtnOption(key);
      }
    },
    [],
  );

  const renderBtns = () => (
    <Stack
      direction="row"
      alignItems="center"
      spacing={3}
      marginLeft="20px"
      padding="24px 6px"
      onClick={onStackButonsClick}
    >
      <Button
        sx={{
          ...btnStyles,
          backgroundColor:
            selectedBtnOption === GHC_EMISSIONS ? COLORS.green : "transparent",
        }}
        variant="outlined"
        data-key={GHC_EMISSIONS}
      >
        GHG Emissions
      </Button>
      <Button
        data-key="consumption"
        sx={{
          ...btnStyles,
          backgroundColor:
            selectedBtnOption === CONSUMPTION ? COLORS.green : "transparent",
        }}
        variant="outlined"
      >
        Consumption
      </Button>
      <Button
        data-key="financial"
        sx={{
          ...btnStyles,
          backgroundColor:
            selectedBtnOption === FINANCIAL ? COLORS.green : "transparent",
        }}
        variant="outlined"
      >
        Financials
      </Button>
      {/* <Button
        data-key="site-specific"
        sx={{
          ...btnStyles,
          backgroundColor:
            selectedBtnOption === SITE_SPECIFIC ? COLORS.green : "transparent",
        }}
        variant="outlined"
      >
        Site-Specific
      </Button> */}
    </Stack>
  );

  const renderFields = () => {
    const disableApplyFiltersBtn =
      isSitesLoading ||
      isAccountNumbersLoading ||
      isCountriesLoading ||
      isCountryRegionsLoading ||
      isFacilityTypesLoading ||
      isScopesLoading ||
      isUtilityTypesLoading ||
      isMeterNumbersLoading;
    return (
      <Box
        my={3}
        mx={3}
        sx={{
          marginRight: "0px",
        }}
      >
        <Box
          sx={{
            ...FILTERS_AND_ACTIONS_WRAPPER,
            marginRight: "0px",
          }}
        >
          <Box
            sx={{
              ...FLEX_ALIGN_CENTER_GAP_2,
              alignItems: "flex-end",
              flexWrap: "wrap",
            }}
          >
            <Box sx={MULTISELECT_WRAPPER}>
              <InputLabel
                sx={{
                  fontSize: "12px",
                  color: "black",
                  fontWeight: 600,
                  marginBottom: "4px",
                }}
                id="fiscal-year-label"
              >
                Fiscal Year
              </InputLabel>
              <FormControl sx={{ minWidth: 220 }}>
                <Select
                  sx={{ height: "40px" }}
                  labelId="fiscal-year-label"
                  id="fiscal-year-select"
                  value={fiscalYear}
                  onChange={(event) =>
                    setFiscalYear(event.target.value as string)
                  }
                  placeholder="Fiscal Year"
                  label="Fiscal Year"
                >
                  {fiscalYears &&
                    fiscalYears.map((year: any) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={MULTISELECT_WRAPPER}>
              <SelectControlledField
                disabled={isCountryRegionsLoading}
                label="Region"
                options={countryRegionsList}
                selectedValues={selectedCountryRegion}
                setSelectedValues={setSelectedCountryRegion}
              />
            </Box>

            <Box sx={MULTISELECT_WRAPPER}>
              <SelectControlledField
                disabled={isCountriesLoading}
                label="Country"
                options={countriesList}
                selectedValues={selectedCountry}
                setSelectedValues={setSelectedCountry}
              />
            </Box>

            <Box sx={MULTISELECT_WRAPPER}>
              <SelectControlledField
                disabled={isFacilityTypesLoading}
                label="Facility type"
                options={facilityTypesList}
                selectedValues={selectedFacilityType}
                setSelectedValues={setSelectedFalityTypeOption}
              />
            </Box>

            <Box sx={MULTISELECT_WRAPPER}>
              <SelectControlledField
                disabled={isScopesLoading}
                label="Scope"
                options={filterScopeList}
                selectedValues={selectedScope}
                setSelectedValues={setSelectedScope}
              />
            </Box>

            <Box sx={MULTISELECT_WRAPPER}>
              <AutocompleteField
                textFieldProps={{
                  label: "Utility type",
                  disabled: isUtilityTypesLoading,
                  placeholder:
                    selectedUtilityTypes.length === 0
                      ? "all selected"
                      : undefined,
                }}
                autocompleteProps={{
                  disabled: isUtilityTypesLoading,
                  multiple: true,
                  value: selectedUtilityTypes,
                  disableClearable: true,
                  blurOnSelect: false,
                  disableCloseOnSelect: true,
                  onChange: (e, data) =>
                    setSelectedUtilityTypes(
                      data as MultiSelectControlledOption[],
                    ),
                  options:
                    selectedBtnOption === GHC_EMISSIONS
                      ? utitlityTypesForEmissions
                      : selectedBtnOption === CONSUMPTION
                      ? utitlityTypesForConsumption
                      : selectedBtnOption === FINANCIAL
                      ? utitlityTypesForFinancial
                      : filterTypes,
                  getOptionLabel: (option) =>
                    (option as MultiSelectControlledOption).label,
                  isOptionEqualToValue: (opt, val) =>
                    (opt as MultiSelectControlledOption).value ===
                    (val as MultiSelectControlledOption).value,

                  renderOption: (props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={checkboxIcon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {(option as MultiSelectControlledOption).label}
                    </li>
                  ),
                  renderTags: (value) => (
                    <Box sx={{ ml: 1 }} component="span">
                      <>
                        {value.length === 1
                          ? (value[0] as MultiSelectControlledOption).label
                          : value.length === utilityTypesOptions.length
                          ? "all selected"
                          : `${value.length} selected`}
                      </>
                    </Box>
                  ),
                }}
              />
            </Box>

            <Box sx={MULTISELECT_WRAPPER}>
              <AutocompleteField
                textFieldProps={{
                  label: "Sites",
                  disabled: isSitesLoading,
                  placeholder:
                    selectedSites.length === 0 ? "all selected" : undefined,
                }}
                autocompleteProps={{
                  disabled: isSitesLoading,
                  multiple: true,
                  value: selectedSites,
                  disableClearable: true,
                  blurOnSelect: false,
                  disableCloseOnSelect: true,
                  onChange: (e, data) =>
                    setSelectedSites(data as MultiSelectControlledOption[]),
                  options: sitesOptions,
                  getOptionLabel: (option) =>
                    (option as MultiSelectControlledOption).label,
                  isOptionEqualToValue: (opt, val) =>
                    (opt as MultiSelectControlledOption).value ===
                    (val as MultiSelectControlledOption).value,

                  renderOption: (props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={checkboxIcon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {(option as MultiSelectControlledOption).label}
                    </li>
                  ),
                  renderTags: (value) => (
                    <Box sx={{ ml: 1 }} component="span">
                      <>
                        {value.length === 1
                          ? (value[0] as MultiSelectControlledOption).label
                          : value.length === sitesOptions.length
                          ? "all selected"
                          : `${value.length} selected`}
                      </>
                    </Box>
                  ),
                }}
              />
            </Box>

            <Box sx={MULTISELECT_WRAPPER}>
              <MultiselectControlledField
                disabled={isAccountNumbersLoading}
                isAllSelectedOnEmptySelection
                label="Account number"
                options={filterAccountNumbers}
                selectedValues={selectedAccountNumbers}
                setSelectedValues={setSelectedAccountNumbers}
              />
            </Box>

            <Box sx={MULTISELECT_WRAPPER}>
              <MultiselectControlledField
                disabled={isMeterNumbersLoading}
                isAllSelectedOnEmptySelection
                label="Meter number"
                options={filterMeterNumbers}
                selectedValues={selectedMeterNumbers}
                setSelectedValues={setSelectedMeterNumbers}
              />
            </Box>
          </Box>

          <Box
            sx={{
              ...FLEX_ALIGN_CENTER_GAP_2,
              width: "100%",
              justifyContent: "end",
            }}
          >
            <Button onClick={onClearFiltersClick} variant="outlined">
              Clear Filters
            </Button>
            <Button
              onClick={onApplyFiltersClick}
              disabled={disableApplyFiltersBtn}
              variant="contained"
            >
              Apply Filters
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <PageDiv
      sx={{
        ".highcharts-credits": { display: "none" },
      }}
    >
      {renderBtns()}
      {renderFields()}
      {/* {selectedBtnOption === "consumption" ? (
        <DashboardTabs tabs={tabs[selectedBtnOption]} />
      ) : (
        tabs[selectedBtnOption][0].content
      )} */}
      <DashboardTabs
        tabs={tabs[selectedBtnOption]}
        selectedBtnOption={selectedBtnOption}
      />
    </PageDiv>
  );
};
