import { Box } from "@mui/material";
import React, { FunctionComponent } from "react";

import { AutocompleteField } from "components";

import { ColorType } from "types";

export const SelectControlledField: FunctionComponent<{
  label?: string;
  disabled?: boolean;
  color?: ColorType;
  message?: string;
  selectedValues: string;
  options: string[];
  setSelectedValues: (data: string) => void;
}> = ({
  label,
  selectedValues,
  options,
  setSelectedValues,
  disabled = false,
  color,
  message,
}) => (
  <AutocompleteField
    textFieldProps={{
      label,
      disabled,
      placeholder: selectedValues || "-Select-",
      message,
      color,
    }}
    autocompleteProps={{
      disabled,
      value: selectedValues,
      disableClearable: true,
      blurOnSelect: false,
      disableCloseOnSelect: true,
      onChange: (e, data) => {
        setSelectedValues(data as string);
      },
      options,
      isOptionEqualToValue: (opt, val) => opt === val,
      renderTags: (value) => (
        <Box sx={{ ml: 1 }} component="span">
          <>{value}</>
        </Box>
      ),
    }}
  />
);
