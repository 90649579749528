/* eslint-disable */
import {
  DatePicker as DatePickerMui,
  DatePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import React from "react";

import { getDateFormat } from "utils";

import { TextField, TextFieldProps } from "./text-field";

export const DatePickerField = ({
  placeholdervalue,
  disabled,
  clearDate,
  emission,
  filterAll,
  format = getDateFormat(),
  textFieldProps = {},
  ...datePickerProps
}: {
  disabled?: boolean;
  textFieldProps?: TextFieldProps;
  clearDate: boolean;
  placeholdervalue?: string;
  emission?:boolean;
  filterAll?: boolean;
} & Omit<
  DatePickerProps<Dayjs>,
  "renderInput" | "date" | "openPicker" | "rawValue"
>) => {
  const [value, setValue] = React.useState<any>();
  React.useEffect(() => {
    if (clearDate === true) {
      setValue(null);
    }
  }, [clearDate]);

  React.useEffect(() => {
    setValue(datePickerProps.value);
  }, []);
  
  const handlerclear=()=>{
      setValue(null);
  }
  React.useEffect(() => {
    if(disabled){
      handlerclear()
    }
  }, [disabled]);
 
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePickerMui
        disabled={disabled}
        format={format}
        {...datePickerProps}
        value={value}
        // onChange={(newValue) => setValue(newValue)}       
        slots={{
          textField: (params: any) => (
            <TextField
              disabled={disabled}
              {...params}
              fullWidth
              value={disabled ? emission ? params.value :"" : filterAll ? "" : params.value}
              // placeholder={placeholdervalue ? placeholdervalue :"MMMM,YYYY"}
              {...textFieldProps}
            />
          ),
        }}
      />
    </LocalizationProvider>
  );
};
