import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { FC, useEffect, useState } from "react";

import { createSite, getRegion, updateSite } from "api";

import { useNotify } from "hooks";

import { QUERY_KEYS } from "consts";

import { COLORS } from "components";
import { AllowedFormValues } from "../types";

interface AddSiteModalProps {
  isOpen: boolean;
  onClose: () => void;
  allowedValues: AllowedFormValues;
  editSite: any;
}

export const AddSiteModal: FC<AddSiteModalProps> = ({
  isOpen,
  onClose,
  allowedValues,
  editSite,
}) => {
  const { countries, siteStatuses, siteTypes, regions, countryRegions } =
    allowedValues;
    const sortedRegions = regions.sort((a, b) => a.localeCompare(b));
    const sortedRegionsAccending = sortedRegions?.sort() || [];
    const sortedCountries = countryRegions.sort((a, b) => a.localeCompare(b));
    const CountriesAccending = countries?.sort() || [];
    const siteTypesAccending = siteTypes?.sort() || [];


  const notify = useNotify();
  const queryClient = useQueryClient();

  const [formValues, setFormValues] = useState<any>({});

  useEffect(() => {
    if (editSite) {
      setFormValues(editSite);
    } else {
      setFormValues({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSite]);

  const { mutateAsync: createSiteRequest } = useMutation(createSite);
  const { mutateAsync: updateSiteRequest } = useMutation(updateSite);

  const handleInputChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const { name, value } = e.target;
    setFormValues((prevValues: any) => ({
      ...prevValues,
      [name as string]: value,
    }));
  };


  useEffect(() => {
    if (!editSite && formValues.country !== undefined) {
      getRegiocode(formValues.zipCode, formValues.country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.zipCode, formValues.country])


  useEffect(() => {
    if (editSite && formValues.multipleRegion === true && formValues.regionUpdated === false) {
      setFormValues((prevValues: any) => ({
        ...prevValues,
        ["region" as string]: "MULTIPLE REGIONS FOUND",
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.multipleRegion, formValues.regionUpdated])


  async function fetchData(dataset: any) {
    const datarespone = await getRegion(dataset);
    const regionValue = datarespone[0];
    if (regionValue !== undefined && regionValue != null) {

      setFormValues((prevValues: any) => ({
        ...prevValues,
        ["region" as string]: regionValue,
      }));
    } else {
      setFormValues((prevValues: any) => ({
        ...prevValues,
        ["region" as string]: null,
      }));
    }
  }

  const getRegiocode = (zipCode: any, country: any) => {

    if ( country != null) {
      const dataset = { zipCode: zipCode !== undefined ? zipCode : null, country }


      fetchData(dataset);

    }

  };

  const handleAddSite = async () => {
    await createSiteRequest(formValues, {
      onError: () => notify.error(`Failed to create site: ${formValues.name}!`),
      onSuccess: () => {
        notify.success(`Site successfully created: ${formValues.name}!`);
        queryClient.invalidateQueries([QUERY_KEYS.SITES]);
      },
    });
    setFormValues({});
    onClose();
  };

  const handleEditSite = async () => {
    const { id } = editSite;
    if (formValues.region === "MULTIPLE REGIONS FOUND") {
      notify.warning("Please make sure to select an Electricity Region Code to calculate your emissions.");
    } else {
      const updatedSite = {
        id,
        ...formValues,
      };
      await updateSiteRequest(updatedSite, {
        onError: () => notify.error(`Failed to edit site: ${formValues.name}!`),
        onSuccess: () => {
          notify.success(`Site successfully edited: ${formValues.name}!`);
          queryClient.invalidateQueries([QUERY_KEYS.SITES]);
        },
      });
      setFormValues({});
      onClose();
    }
  };

  const cancelhandle = () => {
    if (editSite) {
      onClose();
    } else {
      onClose();
      setFormValues({});
    }
  }
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{editSite ? "Edit Site" : "Add New Site"}</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
              marginTop: "7px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Site Name"
                  variant="outlined"
                  fullWidth
                  name="name"
                  value={formValues.name || ""}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Street Address"
                  variant="outlined"
                  fullWidth
                  name="address"
                  value={formValues.address || ""}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="City"
                  variant="outlined"
                  fullWidth
                  name="city"
                  value={formValues.city || ""}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Zipcode"
                  variant="outlined"
                  fullWidth
                  name="zipCode"
                  value={formValues.zipCode || ""}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Type</InputLabel>
                  <Select
                    label="Type"
                    name="type"
                    value={formValues.type || ""}
                    onChange={(e) => handleInputChange(e as any)}
                  >
                    {siteTypesAccending.map((type: string) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    label="Status"
                    name="status"
                    value={formValues.status || ""}
                    onChange={(e) => handleInputChange(e as any)}
                  >
                    {siteStatuses.map((status: string) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Country</InputLabel>
                  <Select
                    label="Country"
                    name="country"
                    value={formValues.country || ""}
                    onChange={(e) => handleInputChange(e as any)}
                  >
                    {CountriesAccending.map((country: string) => (
                      <MenuItem key={country} value={country}>
                        {country}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Country Code</InputLabel>
                  <Select
                    label="Country Code"
                    name="countryRegion"
                    value={formValues.countryRegion || ""}
                    onChange={(e) => handleInputChange(e as any)}
                  >
                    {sortedCountries.map((countryRegion: string) => (
                      <MenuItem key={countryRegion} value={countryRegion}>
                        {countryRegion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Electricity Region Code</InputLabel>
                  <Select
                    sx={{
                      color: (formValues.region === "MULTIPLE REGIONS FOUND") ? 'red' : 'inherit',
                    }}
                    label="Electricity Region Code"
                    name="region"
                    value={formValues.region || ""}
                    onChange={(e) => handleInputChange(e as any)}
                    disabled={!editSite}
                  >

                    {sortedRegionsAccending?.map((region: string) => (
                      <MenuItem
                        key={region}
                        value={region}
                        sx={{ backgroundColor: formValues.regionList?.includes(region) ? COLORS.neutralGhWhite : 'inherit' , fontSize: formValues.regionList?.includes(region) ? '15px' : ''}}

                        autoFocus={formValues.region === "MULTIPLE REGIONS FOUND" ?   formValues.regionList?.includes(region) : false}
                      >
                        {region}
                      </MenuItem>
                    ))}

                 <MenuItem                    
                        value="MULTIPLE REGIONS FOUND"
                        sx={{
                          display: 'none'
                        }}
                      >

                      Multiple Region Codes Found
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelhandle}>Cancel</Button>
        <Button
          onClick={editSite ? handleEditSite : handleAddSite}
          variant="contained"
          color="primary"
        >
          {editSite ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
