import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { FC, useEffect, useState } from "react";

import {
  SiteByIdDTO,
  UnitDescription,
  createCollector,
  getSiteById,
  updateCollector,
} from "api";

import { Switch } from "components";

import { useNotify } from "hooks";

import { QUERY_KEYS } from "consts";

import { checkNullValue } from "utils";

interface CollectorModalProps {
  isOpen: boolean;
  onClose: () => void;
  allowedValues?: any;
  columns?: any;
  site?: SiteByIdDTO;
  editCollector?: any;
  collectorData?: any;
  isCollectorEditNotes?: boolean;
  onCollectorDataUpdate?: (siteData: SiteByIdDTO) => void;
}

export const CollectorModal: FC<CollectorModalProps> = ({
  isOpen,
  onClose,
  columns,
  allowedValues,
  site,
  editCollector,
  collectorData,
  isCollectorEditNotes,
  onCollectorDataUpdate,
}) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const initialFormValues =
    columns &&
    columns.reduce((acc: any, column: any) => {
      acc[column.accessorKey ?? ""] = "";
      return acc;
    }, {} as any);

  useEffect(() => {
    if (editCollector) {
      const updatedValues = columns.reduce((acc: any, column: any) => {
        acc[column.accessorKey ?? ""] = editCollector[column.accessorKey] || "";
        return acc;
      }, {} as any);
      setValues(updatedValues);
    } else {
      setValues(initialFormValues);
    }
    // eslint-disable-next-line
  }, [columns, editCollector]);

  const [values, setValues] = useState<any>(initialFormValues);
  const [notesError, setNotesError] = useState(false);
  const [notes, setNotes] = useState<any>(collectorData?.notes);

  const [availableUnitDescriptions, setAvailableUnitDescriptions] = useState<
    UnitDescription[]
  >([]);

  const [isSpendBasedEstimation, setIsSpendBasedEstimation] = useState(false);

  useEffect(() => {
    if (editCollector) {
      setIsSpendBasedEstimation(editCollector.spendBased);
    }
  }, [editCollector?.spendBased, editCollector]);

  const handleClose = () => {
    setValues(initialFormValues);
    setIsSpendBasedEstimation(false);
    onClose();
  };

  const { mutateAsync: createCollectorRequest } = useMutation(createCollector);

  const { mutateAsync: updateCollectorRequest } = useMutation(updateCollector);

  useEffect(() => {
    setNotes(collectorData?.notes);
  }, [collectorData]);

  const {
    uploadTypes = [],
    frequency = [],
    currencies = [],
    utilityTypes = [],
    utilitySubtypesMap = {},
  } = allowedValues || {};

  const scope = [
    {
      id: "1",
      name: "Scope 1",
    },
    {
      id: "2",
      name: "Scope 2",
    },
    {
      id: "3",
      name: "NO_SCOPE",
    },
  ];

  useEffect(() => {
    if (values.utilityTypeName) {
      const selectedUtilityTypeInfo = utilityTypes.find(
        (ut: any) =>
          ut.name.toLowerCase() === values.utilityTypeName.toLowerCase(),
      );

      if (selectedUtilityTypeInfo) {
        setAvailableUnitDescriptions(selectedUtilityTypeInfo.unitDescription);
      }
    }
  }, [values.utilityTypeName, utilityTypes]);

  const handleEditNotes = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length <= 250) {
      if (!isCollectorEditNotes) {
        setValues({ ...values, notes: value });
      } else {
        setValues({ ...values, notes: value });
        setNotes(value);
      }
      setNotesError(false);
    } else {
      setNotesError(true);
    }
  };

  const handleAddCollector = async () => {
    const createCollectorsRequestData: any = {
      accountNumber: checkNullValue(values.accountNumber),
      allowedDocuments: Array.isArray(values.allowedDocuments)
        ? checkNullValue(values.allowedDocuments)
        : [values.allowedDocuments],
      consumptionUnit: checkNullValue(values.consumptionUnit),
      currency: checkNullValue(values.currency),
      frequency: checkNullValue(values.frequency),
      meterNumber: checkNullValue(values.meterNumber),
      providerName: checkNullValue(values.providerName),
      scope: checkNullValue(values.scope),
      isSpendBased: isSpendBasedEstimation,
      utilitySubType:
        values.utilityTypeName === "Refrigerants" ||
        values.utilityTypeName === "Diesel Vehicle" ||
        values.utilityTypeName === "Gasoline Vehicle" ||
        values.utilityTypeName === "Hybrid Vehicle"
          ? checkNullValue(values.utilitySubType)
          : null,

      siteId: site?.id || 0,
      utilityTypeId:
        utilityTypes.find(
          (ut: any) =>
            ut.name.toLowerCase() === values.utilityTypeName.toLowerCase(),
        )?.id || 0,
      notes: values.notes,
      ...(isSpendBasedEstimation ? { unitCost: 0 } : {}),
    };

    // Check if utility sub type is required and not provided
    if (
      (values.utilityTypeName === "Refrigerants" ||
        values.utilityTypeName === "Diesel Vehicle" ||
        values.utilityTypeName === "Gasoline Vehicle" ||
        values.utilityTypeName === "Hybrid Vehicle") &&
      !values.utilitySubType
    ) {
      notify.error("Utility Subtype is required.");
      return; // Stop further execution
    }

    // Proceed with creating collector if all checks pass
    await createCollectorRequest(createCollectorsRequestData, {
      onError: () => {
        notify.error(`Some error has happened while adding Collector!`);
      },
      onSuccess: () => {
        notify.success(`Collector successfully added!`);
        queryClient.invalidateQueries([QUERY_KEYS.SITES]);
        onClose();
        if (site && onCollectorDataUpdate) {
          getSiteById(site?.id)
            .then((siteData) => {
              onCollectorDataUpdate(siteData);
            })
            .catch((error) => {
              // eslint-disable-next-line
              console.error("Error fetching site data:", error);
            });
        }
      },
    });
  };

  const handleEditCollector = async () => {
    if (!isCollectorEditNotes) {
      const editCollectorsRequestData: any = {
        accountNumber: checkNullValue(values.accountNumber),
        allowedDocuments: Array.isArray(values.allowedDocuments)
          ? checkNullValue(values.allowedDocuments)
          : [values.allowedDocuments],
        consumptionUnit: checkNullValue(values.consumptionUnit),
        currency: checkNullValue(values.currency),
        frequency: checkNullValue(values.frequency),
        meterNumber: checkNullValue(values.meterNumber),
        providerName: checkNullValue(values.providerName),
        scope: checkNullValue(values.scope),
        unitCost: checkNullValue(values.unitCost),
        isSpendBased: isSpendBasedEstimation,
        notes: checkNullValue(values.notes),
        utilitySubType:
          values.utilityTypeName === "Refrigerants" ||
          values.utilityTypeName === "Diesel Vehicle" ||
          values.utilityTypeName === "Gasoline Vehicle" ||
          values.utilityTypeName === "Hybrid Vehicle"
            ? checkNullValue(values.utilitySubType)
            : null,
        siteId: site?.id || 0,
        id: editCollector?.id,
        utilityTypeId:
          utilityTypes.find(
            (ut: any) =>
              ut.name.toLowerCase() === values.utilityTypeName.toLowerCase(),
          )?.id || 0,
      };

      if (
        (values.utilityTypeName === "Refrigerants" ||
          values.utilityTypeName === "Diesel Vehicle" ||
          values.utilityTypeName === "Gasoline Vehicle" ||
          values.utilityTypeName === "Hybrid Vehicle") &&
        !values.utilitySubType
      ) {
        notify.error("Utility Subtype is required.");
        return;
      }
      await updateCollectorRequest(editCollectorsRequestData, {
        onError: () => {
          notify.error(`Some error has happened while editing Collector!`);
        },
        onSuccess: () => {
          notify.success(`Collector successfully edited!`);
          queryClient.invalidateQueries([QUERY_KEYS.SITES]);
          onClose();
          if (site && onCollectorDataUpdate) {
            getSiteById(site?.id)
              .then((siteData) => {
                onCollectorDataUpdate(siteData);
              })
              .catch((error) => {
                // eslint-disable-next-line
                console.error("Error fetching site data:", error);
              });
          }
        },
      });
    } else {
      const notesData = {
        accountNumber: collectorData.accountNumber,
        allowedDocuments: Array.isArray(collectorData.allowedDocuments)
          ? collectorData.allowedDocuments
          : [collectorData.allowedDocuments],
        consumptionUnit: collectorData.consumptionUnit,
        currency: collectorData.currency,
        frequency: collectorData.frequency,
        meterNumber: collectorData.meterNumber,
        providerName: collectorData.providerName,
        notes,
        scope: collectorData.scope,
        unitCost: collectorData.unitCost,
        isSpendBased: isSpendBasedEstimation,
        siteId: site?.id ?? 0,
        id: collectorData?.id,
        utilityTypeId: collectorData.utilityTypeId,
        utilitySubType: collectorData.utilitySubType,
      };
      await updateCollectorRequest(notesData, {
        onError: () => {
          notify.error(`Some error has happened while updating notes!`);
        },
        onSuccess: () => {
          notify.success(`Notes successfully Updated!`);
          onClose();
          if (site && onCollectorDataUpdate) {
            getSiteById(site?.id)
              .then((siteData) => {
                onCollectorDataUpdate(siteData);
              })
              .catch((error) => {
                // eslint-disable-next-line
                console.error("Error fetching site data:", error);
              });
          }
        },
      });
    }
    if (site && onCollectorDataUpdate) {
      getSiteById(site?.id)
        .then((siteData) => {
          onCollectorDataUpdate(siteData);
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error("Error fetching site data:", error);
        });
    }
  };

  const customFieldsOrder = [
    "utilityTypeName",
    "utilitySubType",
    "accountNumber",
    "meterNumber",
    "providerName",
    "allowedDocuments",
    "frequency",
    "consumptionUnit",
    "currency",
    "scope",
    "unitCost",
    "notes",
  ];

  const sortedColumns = customFieldsOrder.map((key) =>
    columns.find((column: any) => column.accessorKey === key),
  );

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {" "}
        {isCollectorEditNotes
          ? "Edit Notes"
          : editCollector
          ? " Utility Provider"
          : " New Utility Provider"}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={4}>
            {sortedColumns &&
              (isCollectorEditNotes
                ? sortedColumns.map((column: any) => {
                    if (column.accessorKey === "notes") {
                      return (
                        <Grid item key={column.accessorKey}>
                          <TextField
                            sx={{ minWidth: "500px" }}
                            id={column.accessorKey}
                            name={column.accessorKey}
                            onChange={handleEditNotes}
                            value={notes || ""}
                            error={notesError}
                            helperText={
                              notesError
                                ? "Notes length should not exceed 200 characters"
                                : ""
                            }
                            multiline
                            rows={3}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })
                : sortedColumns?.map((column: any) => (
                    <Grid item key={column.accessorKey}>
                      {column.accessorKey === "providerName" && (
                        <FormControl
                          sx={{
                            minWidth:
                              column.accessorKey === "notes" ? 400 : 250,
                          }}
                        >
                          <InputLabel
                            shrink={values[column.accessorKey] !== ""}
                            htmlFor={column.accessorKey}
                            sx={{
                              transform: values[column.accessorKey]
                                ? "translate(14px, -4px) scale(0.75)"
                                : "translate(14px, 10px) scale(1)",
                              fontSize: values[column.accessorKey],
                            }}
                          >
                            {column.header}
                          </InputLabel>
                          <TextField
                            id={column.accessorKey}
                            name={column.accessorKey}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                [e.target.name]: e.target.value,
                              })
                            }
                            value={values[column.accessorKey] || ""}
                          />
                        </FormControl>
                      )}
                      {column.accessorKey === "accountNumber" && (
                        <FormControl
                          sx={{
                            minWidth:
                              column.accessorKey === "notes" ? 400 : 250,
                          }}
                        >
                          <InputLabel
                            shrink={values[column.accessorKey] !== ""}
                            htmlFor={column.accessorKey}
                            sx={{
                              transform: values[column.accessorKey]
                                ? "translate(14px, -4px) scale(0.75)"
                                : "translate(14px, 10px) scale(1)",
                              fontSize: values[column.accessorKey],
                            }}
                          >
                            {column.header}
                          </InputLabel>
                          <TextField
                            id={column.accessorKey}
                            name={column.accessorKey}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                [e.target.name]: e.target.value,
                              })
                            }
                            value={values[column.accessorKey] || ""}
                          />
                        </FormControl>
                      )}
                      {column.accessorKey === "meterNumber" && (
                        <FormControl
                          sx={{
                            minWidth:
                              column.accessorKey === "notes" ? 400 : 250,
                          }}
                        >
                          <InputLabel
                            shrink={values[column.accessorKey] !== ""}
                            htmlFor={column.accessorKey}
                            sx={{
                              transform: values[column.accessorKey]
                                ? "translate(14px, -4px) scale(0.75)"
                                : "translate(14px, 10px) scale(1)",
                              fontSize: values[column.accessorKey],
                            }}
                          >
                            {column.header}
                          </InputLabel>
                          <TextField
                            id={column.accessorKey}
                            name={column.accessorKey}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                [e.target.name]: e.target.value,
                              })
                            }
                            value={values[column.accessorKey] || ""}
                          />
                        </FormControl>
                      )}
                      {column.accessorKey === "notes" && (
                        <FormControl
                          sx={{
                            minWidth:
                              column.accessorKey === "notes" ? 400 : 250,
                            marginLeft: isCollectorEditNotes ? "0px" : "-30px",
                          }}
                        >
                          <InputLabel
                            shrink={values[column.accessorKey] !== ""}
                            htmlFor={column.accessorKey}
                            sx={{
                              transform: values[column.accessorKey]
                                ? "translate(14px, -4px) scale(0.75)"
                                : "translate(14px, 10px) scale(1)",
                              fontSize: values[column.accessorKey],
                            }}
                          >
                            {column.header}
                          </InputLabel>
                          <TextField
                            id={column.accessorKey}
                            name={column.accessorKey}
                            onChange={handleEditNotes}
                            value={
                              isCollectorEditNotes ? notes : values.notes || ""
                            }
                            error={notesError}
                            helperText={
                              notesError
                                ? "Notes length should not exceed 250 characters"
                                : ""
                            }
                            multiline
                            rows={3}
                          />
                        </FormControl>
                      )}
                      <Grid xs={8} sm={6}>
                        {column.accessorKey === "allowedDocuments" && (
                          <FormControl sx={{ maxWidth: 250, width: 250 }}>
                            <InputLabel
                              sx={{
                                transform:
                                  values[column.accessorKey] !== ""
                                    ? "translate(14px, -4px) scale(0.75)"
                                    : "translate(14px, 10px) scale(1)",
                              }}
                            >
                              {column.header}
                            </InputLabel>
                            <Select
                              value={values[column.accessorKey] || ""}
                              name={column.accessorKey}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  [e.target.name]: e.target.value,
                                })
                              }
                              label={column.header}
                            >
                              {uploadTypes.map((option: any) => (
                                <MenuItem key={option} value={option}>
                                  {option}{" "}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}

                        {column.accessorKey === "frequency" && (
                          <FormControl sx={{ maxWidth: 250, width: 250 }}>
                            <InputLabel
                              sx={{
                                transform:
                                  values[column.accessorKey] !== ""
                                    ? "translate(14px, -4px) scale(0.75)"
                                    : "translate(14px, 10px) scale(1)",
                              }}
                            >
                              {column.header}
                            </InputLabel>
                            <Select
                              value={values[column.accessorKey] || ""}
                              name={column.accessorKey}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  [e.target.name]: e.target.value,
                                })
                              }
                              label={column.header}
                            >
                              {frequency.map((option: any) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        {column.accessorKey === "consumptionUnit" && (
                          <FormControl sx={{ maxWidth: 250, width: 250 }}>
                            <InputLabel
                              sx={{
                                transform:
                                  values[column.accessorKey] !== ""
                                    ? "translate(14px, -4px) scale(0.75)"
                                    : "translate(14px, 10px) scale(1)",
                              }}
                            >
                              {column.header}
                            </InputLabel>
                            <Select
                              value={values[column.accessorKey] || ""}
                              name={column.accessorKey}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  [e.target.name]: e.target.value,
                                })
                              }
                              label={column.header}
                            >
                              {availableUnitDescriptions.map((option: any) => (
                                <MenuItem key={option} value={option.unit}>
                                  {`${option.unit} (${option.description})`}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        {column.accessorKey === "currency" && (
                          <FormControl sx={{ maxWidth: 250, width: 250 }}>
                            <InputLabel
                              sx={{
                                transform:
                                  values[column.accessorKey] !== ""
                                    ? "translate(14px, -4px) scale(0.75)"
                                    : "translate(14px, 10px) scale(1)",
                              }}
                            >
                              {column.header}
                            </InputLabel>
                            <Select
                              value={values[column.accessorKey] || ""}
                              name={column.accessorKey}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  [e.target.name]: e.target.value,
                                })
                              }
                              label={column.header}
                            >
                              {currencies.map((option: any) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        {column.accessorKey === "utilityTypeName" && (
                          <FormControl sx={{ maxWidth: 250, width: 250 }}>
                            <InputLabel
                              sx={{
                                transform:
                                  values[column.accessorKey] !== ""
                                    ? "translate(14px, -4px) scale(0.75)"
                                    : "translate(14px, 10px) scale(1)",
                              }}
                            >
                              {column.header}
                            </InputLabel>
                            <Select
                              value={values[column.accessorKey] || ""}
                              name={column.accessorKey}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  [e.target.name]: e.target.value,
                                })
                              }
                              label={column.header}
                            >
                              {Object.values(utilityTypes).map((type: any) => (
                                <MenuItem key={type.id} value={type.name}>
                                  {type.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        {column.accessorKey === "utilitySubType" && (
                          <FormControl sx={{ maxWidth: 250, width: 250 }}>
                            <InputLabel
                              sx={{
                                transform:
                                  values[column.accessorKey] !== ""
                                    ? "translate(14px, -4px) scale(0.75)"
                                    : "translate(14px, 10px) scale(1)",
                              }}
                              disabled={
                                !(
                                  values.utilityTypeName === "Refrigerants" ||
                                  values.utilityTypeName === "Diesel Vehicle" ||
                                  values.utilityTypeName ===
                                    "Gasoline Vehicle" ||
                                  values.utilityTypeName === "Hybrid Vehicle"
                                )
                              }
                            >
                              {column.header}
                            </InputLabel>
                            <Select
                              disabled={
                                !(
                                  values.utilityTypeName === "Refrigerants" ||
                                  values.utilityTypeName === "Diesel Vehicle" ||
                                  values.utilityTypeName ===
                                    "Gasoline Vehicle" ||
                                  values.utilityTypeName === "Hybrid Vehicle"
                                )
                              }
                              value={values[column.accessorKey] || ""}
                              name={column.accessorKey}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  [e.target.name]: e.target.value,
                                })
                              }
                              label={column.header}
                            >
                              {values.utilityTypeName === "Refrigerants" ? (
                                utilitySubtypesMap.Refrigerants.map(
                                  (subtype: any) => (
                                    <MenuItem key={subtype} value={subtype}>
                                      {subtype}
                                    </MenuItem>
                                  ),
                                )
                              ) : values.utilityTypeName ===
                                "Diesel Vehicle" ? (
                                utilitySubtypesMap["Diesel Vehicle"].map(
                                  (subtype: any) => (
                                    <MenuItem key={subtype} value={subtype}>
                                      {subtype}
                                    </MenuItem>
                                  ),
                                )
                              ) : values.utilityTypeName ===
                                "Gasoline Vehicle" ? (
                                utilitySubtypesMap["Gasoline Vehicle"].map(
                                  (subtype: any) => (
                                    <MenuItem key={subtype} value={subtype}>
                                      {subtype}
                                    </MenuItem>
                                  ),
                                )
                              ) : values.utilityTypeName ===
                                "Hybrid Vehicle" ? (
                                utilitySubtypesMap["Hybrid Vehicle"].map(
                                  (subtype: any) => (
                                    <MenuItem key={subtype} value={subtype}>
                                      {subtype}
                                    </MenuItem>
                                  ),
                                )
                              ) : (
                                <MenuItem value="">Select</MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        )}
                        {column.accessorKey === "scope" && (
                          <FormControl sx={{ maxWidth: 250, width: 250 }}>
                            <InputLabel
                              sx={{
                                transform:
                                  values[column.accessorKey] !== ""
                                    ? "translate(14px, -4px) scale(0.75)"
                                    : "translate(14px, 10px) scale(1)",
                              }}
                            >
                              {column.header}
                            </InputLabel>
                            <Select
                              value={values[column.accessorKey] || ""}
                              name={column.accessorKey}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  [e.target.name]: e.target.value,
                                })
                              }
                              label={column.header}
                            >
                              {scope.map((type: any) => (
                                <MenuItem key={type.id} value={type.name}>
                                  {type.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        {isSpendBasedEstimation &&
                          column.accessorKey === "unitCost" && (
                            <FormControl
                              sx={{
                                minWidth: 250,
                              }}
                            >
                              <InputLabel
                                shrink
                                htmlFor={column.accessorKey}
                                sx={{
                                  transform:
                                    "translate(14px, -1px) scale(0.75)",
                                  fontSize: values[column.accessorKey],
                                }}
                              >
                                Consumption Unit Cost
                              </InputLabel>
                              <TextField
                                id={column.accessorKey}
                                name={column.accessorKey}
                                disabled
                                helperText={
                                  <FormHelperText
                                    sx={{
                                      color: "red",
                                      fontFamily: "Manrope",
                                      fontSize: "11px",
                                      whiteSpace: "pre-wrap",
                                      overflow: "hidden",
                                      width: "240px",

                                      "&.MuiFormHelperText-root": {
                                        margin: "0px",
                                        marginLeft: "-11px",
                                        color: "red",
                                      },
                                    }}
                                  >
                                    Define Unit Cost within the Utility
                                    Providers table to see historical Unit Cost
                                    for this country
                                  </FormHelperText>
                                }
                                value={
                                  editCollector &&
                                  editCollector.unitCost !== null
                                    ? editCollector.unitCost
                                    : `${values.currency}/${values.consumptionUnit}`
                                }
                              />
                            </FormControl>
                          )}
                      </Grid>
                    </Grid>
                  )))}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          ml={2}
        >
          <Grid item>
            <Switch
              isChecked={isSpendBasedEstimation}
              label="Spend Based Estimation"
              onChange={() =>
                setIsSpendBasedEstimation(!isSpendBasedEstimation)
              }
            />
          </Grid>
          <Grid item>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={
                editCollector || isCollectorEditNotes
                  ? handleEditCollector
                  : handleAddCollector
              }
              variant="contained"
              color="primary"
            >
              {editCollector || isCollectorEditNotes ? "Save" : "Add"}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
