import { Chip, Stack } from "@mui/material";
import React, { Dispatch, FunctionComponent, SetStateAction } from "react";

import { EyeIcon } from "assets";

import { Body2Regular, COLORS, Label12SemiBold } from "components";

import { USER_ROLES, USER_ROLE_COLOR, UserRole } from "recoils";

const CHIP_STYLES = {
  border: "none",
  borderRadius: 2,
  padding: "4px 12px",
};

export const UsersFilters: FunctionComponent<{
  onFilterChange: Dispatch<SetStateAction<UserRole | null>>;
  isDisabled: boolean;
}> = ({ onFilterChange, isDisabled }) => (
  <Stack alignItems="center" direction="row" spacing={2}>
    <Body2Regular>View:</Body2Regular>
    <Chip
      sx={{ bgcolor: COLORS.mintCream, ...CHIP_STYLES }}
      icon={<EyeIcon />}
      label={<Label12SemiBold>All</Label12SemiBold>}
      variant="outlined"
      onClick={() => onFilterChange(null)}
      disabled={isDisabled}
    />
    <Chip
      sx={{ bgcolor: USER_ROLE_COLOR.auditor, ...CHIP_STYLES }}
      icon={<EyeIcon />}
      label={<Label12SemiBold>Auditor</Label12SemiBold>}
      variant="outlined"
      onClick={() => onFilterChange(USER_ROLES.AUDITOR)}
      disabled={isDisabled}
    />
    <Chip
      sx={{ bgcolor: USER_ROLE_COLOR.admin, ...CHIP_STYLES }}
      icon={<EyeIcon />}
      label={<Label12SemiBold>Sustainability manager</Label12SemiBold>}
      variant="outlined"
      onClick={() => onFilterChange(USER_ROLES.ADMIN)}
      disabled={isDisabled}
    />
    <Chip
      sx={{ bgcolor: USER_ROLE_COLOR.site_manager, ...CHIP_STYLES }}
      icon={<EyeIcon />}
      label={<Label12SemiBold>Data provider</Label12SemiBold>}
      variant="outlined"
      onClick={() => onFilterChange(USER_ROLES.SITE_MANAGER)}
      disabled={isDisabled}
    />
    <Chip
      sx={{ bgcolor: USER_ROLE_COLOR.enabled, ...CHIP_STYLES }}
      icon={<EyeIcon />}
      label={<Label12SemiBold>De-activated Users</Label12SemiBold>}
      variant="outlined"
      onClick={() => onFilterChange(USER_ROLES.ENABLED)}
      disabled={isDisabled}
    />
  </Stack>
);
