import { Box } from "@mui/material";
import React, { FunctionComponent } from "react";

import { AutocompleteField } from "components";

import { ColorType } from "types";

export interface MultiSelectControlledOption {
  label: string;
  value: string | number;
}

export const MultiselectControlledField: FunctionComponent<{
  label?: string;
  disabled?: boolean;
  color?: ColorType;
  message?: string;
  selectedValues: MultiSelectControlledOption[];
  options: MultiSelectControlledOption[];
  setSelectedValues: (data: MultiSelectControlledOption[]) => void;
  isAllSelectedOnEmptySelection?: boolean;
}> = ({
  label,
  selectedValues,
  options,
  setSelectedValues,
  disabled,
  isAllSelectedOnEmptySelection = false,
  color,
  message,
}) => (
  <AutocompleteField
    textFieldProps={{
      label,
      disabled,
      placeholder:
        selectedValues.length === 0 && isAllSelectedOnEmptySelection
          ? "all selected"
          : undefined,
      message,
      color,
    }}
    autocompleteProps={{
      disabled,
      multiple: true,
      value: selectedValues,
      disableClearable: true,
      blurOnSelect: false,
      disableCloseOnSelect: true,
      onChange: (e, data) =>
        setSelectedValues(data as MultiSelectControlledOption[]),
      options,
      isOptionEqualToValue: (opt, val) =>
        (opt as MultiSelectControlledOption).value ===
        (val as MultiSelectControlledOption).value,
      renderTags: (value) => (
        <Box sx={{ ml: 1 }} component="span">
          <>
            {value.length === 1
              ? (value[0] as MultiSelectControlledOption).label
              : value.length === options.length
              ? "all selected"
              : `${value.length} selected`}
          </>
        </Box>
      ),
    }}
  />
);
