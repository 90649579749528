/* eslint-disable */
import DoneIcon from "@mui/icons-material/Done";
import {
  Box,
  Button,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  activateRecDetails,
  deActivateRecDetails,
  editRecDocumentDetails,
  getRecDocumentDetails,
} from "api/rec";

import {
  Body1SemiBold,
  InputFieldWrapper,
  RecPdfFooter,
  RecPdfPreview,
} from "components";
import { RecDatePickerFormField } from "components/inputs/rec-date-picker-form-field";

import { useGetAllowedValues, useNotify } from "hooks";

import { definedValueOrDefault, getZeroTimeStamp } from "utils";

import { getTextFieldColorStateProps } from "../documents";

export const RecUpload: React.FunctionComponent = () => {
  const [scaleChange, setScaleChange] = useState(false);
  const [scale, setScale] = useState(0.75);
  dayjs.extend(timezone);
  const { recId } = useParams();
  const navigate = useNavigate();
  const notify = useNotify();
  const location = useLocation();
  const receivedData = location.state;

  const { data: recData, mutateAsync: getRecDocument } = useMutation(
    getRecDocumentDetails,
  );

  const { mutateAsync: activateRec } = useMutation(activateRecDetails);

  const { mutateAsync: deActivateRec } = useMutation(deActivateRecDetails);

  const { mutateAsync: editRec } = useMutation(editRecDocumentDetails);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [selectedUtilityType, setSelectedUtilityType] = useState("");

  const [disableBtn, setDisableBtn] = useState<boolean>(true);
  const recDocumentDetails = recData !== undefined && recData?.data;

  const { data: allowedValues } = useGetAllowedValues();

  const sortedUtilityTypes: any = allowedValues?.utilityTypes.sort(
    (a: any, b: any) => a.name.localeCompare(b.name),
  );

  const defaultValues = useMemo(() => {
    return {
      utilityProvider: recDocumentDetails?.utilityProvider,
      utilityType:recData?.data.utilityTypeName,
      renewableEnergy:
        recData !== undefined &&
        definedValueOrDefault(recData?.data.renewableEnergy, ""),
      startDate: moment(recData?.data.startDate, "YYYY-MM-DD"),
      endDate: moment(recData?.data.endDate, "YYYY-MM-DD"),
      country:
        recData !== undefined &&
        definedValueOrDefault(recData?.data.country, ""),
    };
  }, [recDocumentDetails, recData]);

  // useEffect(() => {
  //   if (recData) {
  //     setSelectedUtilityType(recData.data.utilityTypeName);
  //   }
  // }, [recData]);

  useEffect(() => {
    const statusArr =
      recDocumentDetails &&
      recDocumentDetails?.collectorRecsDtoList
        .filter((t: any) => t.recsActive === true)
        .map((item: any) => {
          return item.collectorId;
        });
    setSelectedOptions(statusArr !== false ? statusArr : []);
  }, [recDocumentDetails]);

  const { control, formState, reset, getValues } = useForm<any>({
    defaultValues,
    mode: "onChange",
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset, recData, defaultValues]);

  useEffect(() => {
    if (getValues("startDate") === null) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [getValues("startDate")]);

  const handleSaveBtnClick = () => {
    const selectedIds: any = [];
    const deselectedIds: any = [];

    recDocumentDetails?.collectorRecsDtoList &&
      recDocumentDetails?.collectorRecsDtoList.forEach((t: any) => {
        if (selectedOptions.includes(t.collectorId)) {
          selectedIds.push(t.collectorId);
        } else {
          deselectedIds.push(t.collectorId);
        }
      });
    deActivateRec({
      recId: parseInt(recId!, 10),
      collectorIds: deselectedIds,
    });

    activateRec(
      {
        recId: parseInt(recId!, 10),
        collectorIds: selectedIds,
      },
      {
        onSuccess: () => {
          // notify.success(`Rec site is Updated !`);
        },
      },
    );

    const endDate =
      getValues("endDate") === "Invalid Date" || getValues("endDate") === null
        ? null
        : new Date(getValues("endDate"));

    const startDate =
      getValues("startDate") === "Invalid Date" ||
      getValues("startDate") === null
        ? null
        : new Date(getValues("startDate"));

    const percentage = getValues("renewableEnergy");

    editRec(
      {
        recId,
        payload: {
          endDate:
            dayjs(endDate).format("YYYY-MM-DDTHH:mm:ss[Z]") === "Invalid Date"
              ? null
              : dayjs(endDate).format("YYYY-MM-DDT00:00:00[Z]"),
          startDate:
            dayjs(startDate).format("YYYY-MM-DDTHH:mm:ss[Z]") === "Invalid Date"
              ? null
              : dayjs(startDate).format("YYYY-MM-DDT00:00:00[Z]"),
          renewableEnergy: percentage,
        },
      },
      {
        onSuccess: () => {
          getRecDocument(recId);
          notify.success(`RECs have been updated`);
        },
      },
    );
  };

  useEffect(() => {
    getRecDocument(recId);
  }, [recId, getRecDocument]);

  const handleChange = (event: any) => {
    const selectedItems = event.target.value;
    setSelectedOptions(selectedItems);
  };

  // Function to get the ID of an option
  const getOptionId = (value: any) => {
    const parts = value.split(" - ");
    return parts[parts.length - 1];
  };

  console.log("***",recDocumentDetails?.collectorRecsDtoList)

  const handleFileUploaded = (status: any) => {
    if (status === true) {
      getRecDocument(recId);
    }
  };

  const handleFileReUploaded = (status: any) => {
    if (status === true) {
      getRecDocument(recId);
    }
  };

  return (
    <>
      {recData !== undefined && (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Box
            sx={{
              display: "flex",
              p: 3,
              flexGrow: 1,
              flexDirection: "row",
              overflow: "auto",
            }}
          >
            {/* Section 1 */}
            <RecPdfPreview
              downloadUrl={recDocumentDetails?.downloadUrl}
              scale={scale}
              isScaleChange={scaleChange}
              isFileUploaded={handleFileUploaded}
            />
            {/* Section 2 */}
            <Box
              sx={{
                ml: 4,
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  mb: 2,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              ></Box>
              <Box component="section" mb={3} mt={2}>
                <Body1SemiBold mb={3}>REC Details</Body1SemiBold>

                <Stack direction="row" mb={2} spacing={3}>
                  <InputFieldWrapper label="Utility Provider">
                    <Controller
                      name="utilityProvider"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          helperText={error ? error.message : null}
                          size="small"
                          error={!!error}
                          onChange={onChange}
                          disabled
                          value={value}
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </InputFieldWrapper>
                  <InputFieldWrapper label="Utility Type">
                    <Controller
                      name="utilityType"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          helperText={error ? error.message : null}
                          size="small"
                          error={!!error}
                          onChange={onChange}
                          disabled
                          value={value}
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </InputFieldWrapper>
                </Stack>
                <Stack direction="row" mb={2} spacing={3}>
                  <InputFieldWrapper label="Country">
                    <Controller
                      name="country"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          helperText={error ? error.message : null}
                          size="small"
                          error={!!error}
                          onChange={onChange}
                          value={value}
                          fullWidth
                          disabled
                          variant="outlined"
                        />
                      )}
                    />
                  </InputFieldWrapper>
                  <InputFieldWrapper label="Coverage Percentage">
                    <Controller
                      name="renewableEnergy"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          helperText={error ? error.message : null}
                          size="small"
                          error={!!error}
                          onChange={onChange}
                          value={value}
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </InputFieldWrapper>
                </Stack>

                <Stack direction="row" mb={2} spacing={3}>
                  <RecDatePickerFormField
                    name="startDate"
                    label="REC Start Date"
                    control={control}
                    disabled={false}
                    rules={{
                      required: receivedData?.startDate,
                    }}
                    {...getTextFieldColorStateProps(
                      "startDate",
                      formState.errors,
                    )}
                  />

                  <RecDatePickerFormField
                    name="endDate"
                    label="REC End Date (Optional)"
                    control={control}
                    disabled={false}
                    rules={{
                      required: receivedData?.endDate,
                    }}
                    {...getTextFieldColorStateProps(
                      "endDate",
                      formState.errors,
                    )}
                  />
                </Stack>

                <Stack direction="row" spacing={3}>
                  <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <InputFieldWrapper label="Sites Applied">
                      <Select
                        multiple
                        value={selectedOptions}
                        onChange={handleChange}
                        autoWidth
                        fullWidth
                        sx={{ height: 40 }}
                        input={<OutlinedInput />}
                        renderValue={(selected) => (
                          <div>
                            <MenuItem>{selected.length} Selected</MenuItem>
                          </div>
                        )}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                        }}
                      >
                        {recDocumentDetails?.collectorRecsDtoList.map(
                          (option: any) => {
                            const itemId = getOptionId(
                              `${option.siteName} - ${option.provider} - ${option.collectorId}`,
                            );
                            const isSelected = selectedOptions.includes(
                              option.collectorId,
                            );

                            const siteName =
                              option.siteName !== null
                                ? `${option.siteName}`
                                : "";
                            const accountName =
                              option.accountNumber !== null
                                ? `- ${option.accountNumber}`
                                : "";
                            const meterNumber =
                              option.meterNumber !== null
                                ? `- ${option.meterNumber}`
                                : "";

                            const utilityType = getValues("utilityType")

                            return (
                              <MenuItem
                                key={itemId}
                                value={option.collectorId}
                                selected={isSelected}
                                sx={{ width: "100%" }}
                              >
                                <ListItemText
                                  secondary={`${siteName} -${utilityType} ${accountName} ${meterNumber}`}
                                />
                                {isSelected ? (
                                  <DoneIcon style={{ marginLeft: "12px" }} />
                                ) : null}
                              </MenuItem>
                            );
                          },
                        )}
                      </Select>
                    </InputFieldWrapper>
                  </FormControl>
                </Stack>
              </Box>

              <Box
                sx={{
                  mt: 4,
                  lexGrow: 1,
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button onClick={() => navigate(-1)}>Cancel</Button>
                <Button
                  onClick={() => handleSaveBtnClick()}
                  variant="contained"
                  disabled={disableBtn}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Box>

          <RecPdfFooter
            fileName={recDocumentDetails?.recsFileName}
            setScale={setScale}
            downloadUrl={recDocumentDetails?.downloadUrl}
            actionsDisabled={false}
            setScaleChange={setScaleChange}
            isFileUploaded={handleFileReUploaded}
          />
        </Box>
      )}
    </>
  );
};
