import React from "react";

import { EsgEmissionSummaryIcon } from "assets";

export const SITES_EMISSIONS_PAGE_SCOPE3 = {
  ABSOLUTE_PATH: "/sites-emissions-scope3",
  TITLE: "Emission Overview",
  ICON: <EsgEmissionSummaryIcon />,
  IS_TBD: false,
  renderMenu: false,
};
