import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getUtilityBillById, setFeedback } from "api";

import { ThumbDown, ThumbUP } from "assets";
import "./css/style.css"

import {
  Body1SemiBold,
  CloseButton,
  DrawerView,
  Loading,
  PdfFooter,
  PdfPreview,
  RoutedTabs,
  Tooltip,
  UTILITY_BILL_TYPE_TO_MESSAGE,
  UtilityBillStatus,
  UtilityBillStatusKey,
  UtilityBillTypeKey,
} from "components";

import { useUtilityBillById, useUtilityBillWSConnection } from "hooks";

import { getFromLocalStorage, setToLocalStorage } from "utils";

import { UTILITY_BILL_PAGE } from "./consts";
import { UTILITY_BILL_ALL_DATAENTRIES_VIEW } from "./utility-bill-allDataEntries-view/consts";
import { UTILITY_BILL_COMMENTS_VIEW } from "./utility-bill-comments-view";
import { UTILITY_BILL_FORM_VIEW } from "./utility-bill-form-view";

export const UtilityBill: React.FunctionComponent = () => {
  const { billId } = useParams<{ [UTILITY_BILL_PAGE.KEY]: string }>();
  const { data: utilityBill, isLoading } = useUtilityBillById(billId!);

  const [isOpen, setIsOpen] = useState(!!billId);
  const [scale, setScale] = useState(0.75);
  const [scaleChange, setScaleChange] = useState(false);
  const [hoveredMessage, setHoveredMessage] = useState<number | null>(null);
  const [thumbUpClicked, setThumbUpClicked] = useState(
    utilityBill && utilityBill.feedback === "Thumbs_Up",
  );
  const [thumbDownClicked, setThumbDownClicked] = useState(
    utilityBill && utilityBill.feedback === "Thumbs_Down",
  );
  const algorithm = getFromLocalStorage("algorithm");

  useUtilityBillWSConnection(billId!);
  const navigate = useNavigate();
  setToLocalStorage("setid", utilityBill?.id);
  const getType = getFromLocalStorage("type") || "";
  const onClose = () => {
    let getURL: string = "";
    if (getType.includes("Upload")) {
      getURL = getFromLocalStorage("Upload_URL") || "";
    }
    if (getType.includes("Emissions")) {
      getURL = getFromLocalStorage("Emission_URL") || "";
    }
    if (getType.includes("Summary")) {
      getURL = getFromLocalStorage("Summary_URL") || "";
    } 

    navigate(getURL);
    // window.localStorage.clear();
    localStorage.removeItem("type");
    localStorage.removeItem("Upload_URL");

    setIsOpen((state) => !state);
  };

  const addFeedback = async (type: string) => {
    if (utilityBill) {
      try {
        await setFeedback({
          id: utilityBill.id,
          feedback: type,
        });

        if (type === "Thumbs_Up") {
          setThumbUpClicked(true);
          setThumbDownClicked(false);
        } else if (type === "Thumbs_Down") {
          setThumbDownClicked(true);
          setThumbUpClicked(false);
        }
      } catch (error) {
        console.error("Error updating feedback:", error);
      }
    }
  };

  useEffect(() => {
    const fetchUtilityBill = async () => {
      if (utilityBill) {
        const response = await getUtilityBillById(utilityBill.id);
        if (response.feedback === "Thumbs_Up") {
          setThumbUpClicked(true);
          setThumbDownClicked(false);
        } else if (response.feedback === "Thumbs_Down") {
          setThumbDownClicked(true);
          setThumbUpClicked(false);
        }
      }
    };

    fetchUtilityBill();
  }, [utilityBill, billId, thumbDownClicked, thumbUpClicked]);

  const onPreviousBillViewClick = async (id: any) => {
    window.open(`${UTILITY_BILL_PAGE.ABSOLUTE_PATH(id as string)}`);
    try {
      const response = await getUtilityBillById(id);
      setToLocalStorage(
        "document",
        JSON.stringify({
          documentGuid: response.documentGuid,
          filename: response.filename,
        }),
      );
    } catch (error) {
      console.error("Error fetching utility bill:", error);
      throw error;
    }
  };

  if (scale <= 0.25) {
    setScale(0.75);
  }
  // useEffect(() => {
  //   console.log(utilityBill?.errorMessage?.includes("Duplicate Bill Uploaded"))
  // },[utilityBill]);

  return (
    <DrawerView isOpen={isOpen} onClose={onClose}>
      {isLoading ? (
        <Loading />
      ) : (
        utilityBill && (
          <Box
            sx={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <Box
              sx={{
                display: "flex",
                p: 3,
                flexGrow: 1,
                flexDirection: "row",
                overflow: "auto",
              }}
            >
              <PdfPreview
                downloadUrl={utilityBill.downloadUrl}
                scale={scale}
                isScaleChange={scaleChange}
                billId={billId}
              />
              <Box
                sx={{
                  ml: 4,
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {(algorithm === "IDP Fallback" ||
                  algorithm === "IDP A/B Testing") && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "#F1F7FF",
                          padding: "20px",
                          borderRadius: "10px",
                          maxWidth: "1034px",
                          marginRight: "10px",
                          border: '1px solid',
                          borderColor: '#BFDBFE'
                        }}
                      >
                        <div
                          style={{
                            color: "#1E40AF",
                            fontSize: "18px",
                            fontWeight: "700",
                          }}
                        >
                          Data Populated with New Algorithm
                        </div>
                        <div
                          style={{
                            marginTop: "15px",
                            fontSize: "16px",
                            color: "#636976",
                            fontWeight: "500",
                            maxWidth: "960px",
                            lineHeight: "23px",
                          }}
                        >
                          We’re beta testing our new AI technology as part of our
                          product enhancement initiative. Please review the bill
                          below to ensure that the data has been extracted
                          accurately.{" "}
                        </div>
                        <div
                          style={{
                            marginTop: "15px",
                            fontSize: "14px",
                            fontWeight: "600",
                            maxWidth: "900px",
                          }}
                        >
                          If the bill is 100% accurate, please indicate with a
                          Thumbs Up. If inaccurate, please indicate with a Thumbs
                          Down and correct the data below.
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "15px",
                            gap: '1rem'
                          }}
                        >
                          <div
                            className="thumbUp"
                            role="button"
                            onClick={() => {
                              addFeedback("Thumbs_Up");
                            }}
                            tabIndex={0}
                            onKeyDown={() => { }}
                            style={{
                              backgroundColor: thumbUpClicked
                                ? "#C7E9C1"
                                : "white",
                              border: `1px solid ${thumbUpClicked ? '#5CB152' : '#E5E7EB'}`
                            }}
                          >
                            <ThumbUP
                              onClick={() => {
                                addFeedback("Thumbs_Up");
                              }}
                            />
                          </div>
                          <div
                            onClick={() => {
                              addFeedback("Thumbs_Down");
                            }}
                            tabIndex={0}
                            onKeyDown={() => { }}
                            role="button"
                            className="thumbDown"
                            style={{
                              backgroundColor: thumbDownClicked
                                ? "#F5C5C5"
                                : "white",
                              border: `1px solid ${thumbDownClicked ? '#991B1B' : '#E5E7EB'}`
                            }}
                          >
                            <ThumbDown
                              onClick={() => {
                                addFeedback("Thumbs_Down");
                              }}
                            />
                          </div>
                        </div>
                      </Box>

                      <CloseButton onClick={onClose} variant="outlined" />
                    </Box>
                  )}

                <Box
                  sx={{
                    mb: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 3,
                  }}
                >
                 
                  <Box sx={{ display: "flex", gap: 2 }}>
                  
                    {utilityBill.type && (
                      <>
                        <Body1SemiBold>
                          {
                            UTILITY_BILL_TYPE_TO_MESSAGE[
                            utilityBill.type as UtilityBillTypeKey
                            ]
                          }
                        </Body1SemiBold>
                        <Body1SemiBold>|</Body1SemiBold>
                      </>
                    )}
                    <UtilityBillStatus status={utilityBill.status as UtilityBillStatusKey} />
                    <Body1SemiBold>Document status:</Body1SemiBold>

                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <div>
                        {utilityBill?.anomalies?.length ? (
                          (() => {
                            utilityBill?.anomalies.sort((a: any, b: any) =>
                              a.message.localeCompare(b.message),
                            );
                            return utilityBill?.anomalies.map(
                              (anomaly: any, index: any) => (
                                <div key={anomaly.previousBillGuid}>
                                  <Tooltip
                                    title={
                                      anomaly.anomalyType === "MULTIPLE_UPLOAD"
                                        ? utilityBill.status ===
                                          "VERIFICATION_FAILED" &&
                                          anomaly.previousBillExists === false
                                          ? "The bill is already deleted, Please manually confirm this bill."
                                          : ""
                                        : ""
                                    }
                                  >
                                    {/* eslint-disable-next-line */}
                                    <a
                                      href="#"
                                      style={{
                                        marginLeft: "5px",
                                        textDecoration: "none",
                                        cursor: "pointer",
                                        color:
                                          hoveredMessage === index
                                            ? "black"
                                            : "grey",
                                      }}
                                      onMouseEnter={
                                        anomaly.previousBillGuid
                                          ? () => setHoveredMessage(index)
                                          : undefined
                                      }
                                      onMouseLeave={() => setHoveredMessage(null)}
                                      onClick={() =>
                                        utilityBill.status ===
                                          "VERIFICATION_FAILED" &&
                                          anomaly.previousBillExists === false
                                          ? ""
                                          : anomaly.previousBillGuid
                                            ? onPreviousBillViewClick(
                                              anomaly.previousBillGuid,
                                            )
                                            : ""
                                      }
                                    >
                                      {anomaly.anomalyType ===
                                        "MULTIPLE_UPLOAD" ? (
                                        utilityBill.status ===
                                          "VERIFICATION_FAILED" &&
                                          anomaly.previousBillExists === false ? (
                                          <>
                                             <span>{anomaly?.message ? `• ${anomaly.message}` : ""}</span>&nbsp;
                                          </>
                                        ) : (
                                          <>
                                             <span>{anomaly?.message ? `• ${anomaly.message}` : ""}</span>&nbsp;
                                          </>
                                        )
                                      ) : utilityBill.errorMessage?.includes(
                                        "Duplicate Bill Uploaded",
                                      ) &&
                                        anomaly.previousBillExists === false ? (
                                        <Tooltip title="The corresponding duplicate bill has already been deleted. Please manually confirm this bill.">
                                          <>
                                          <span>{anomaly?.message ? `• ${anomaly.message}` : ""}</span>&nbsp;
                                          </>
                                        </Tooltip>
                                      ) : (
                                        <>
                                           <span>{anomaly?.message ? `• ${anomaly.message}` : ""}</span>&nbsp;
                                        </>
                                      )}
                                    </a>
                                  </Tooltip>
                                </div>
                              ),
                            );
                          })()


                        ) : (
                          <>
                            {utilityBill.errorMessage?.includes(
                              "Duplicate Bill Uploaded",
                            ) && utilityBill?.anomalies?.length !== 0 ? (
                              <Tooltip title="The corresponding duplicate bill has already been deleted. Please manually confirm this bill.">
                                  <span>{utilityBill?.errorMessage ? `• ${utilityBill?.errorMessage}` : ""}&nbsp;</span>
                              </Tooltip>
                            ) : (
                              <span>{utilityBill?.errorMessage ? `• ${utilityBill?.errorMessage}` : ""}&nbsp;</span>
                            )}
                          </>
                        )}
                        {(utilityBill?.errorMessage?.includes("future dates") && utilityBill?.anomalies?.length !== 0) && <div style={{ color: 'grey', marginLeft: "5px", }}>&#8226;  {utilityBill.errorMessage}</div>}
                      </div>

                    </Box>
                  </Box>
                  {(algorithm !== "IDP Fallback" ||
                    algorithm !== "IDP A/B Testing") && (
                      <CloseButton onClick={onClose} variant="outlined" />
                    )}
                </Box>

                <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
                  <RoutedTabs
                    tabs={[
                      {
                        label: UTILITY_BILL_ALL_DATAENTRIES_VIEW.TITLE,
                        to: UTILITY_BILL_ALL_DATAENTRIES_VIEW.ABSOLUTE_PATH(
                          utilityBill.id,
                        ),
                        disable: false,
                      },
                      {
                        icon: UTILITY_BILL_FORM_VIEW.ICON,
                        label: UTILITY_BILL_FORM_VIEW.TITLE,
                        to: UTILITY_BILL_FORM_VIEW.ABSOLUTE_PATH(
                          utilityBill.id,
                        ),
                        disable: false,
                      },
                      {
                        icon: UTILITY_BILL_COMMENTS_VIEW.ICON,
                        label: UTILITY_BILL_COMMENTS_VIEW.TITLE(
                          utilityBill.numberOfComments,
                        ),
                        to: UTILITY_BILL_COMMENTS_VIEW.ABSOLUTE_PATH(
                          utilityBill.id,
                        ),
                        disable: false,
                      },
                    ]}
                  />
                </Box>
              </Box>
            </Box>

            <PdfFooter
              fileName={utilityBill.filename}
              setScale={setScale}
              downloadUrl={utilityBill.downloadUrl as string}
              actionsDisabled={isLoading}
              setScaleChange={setScaleChange}
            />
          </Box>
        )
      )}
    </DrawerView>
  );
};
