import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PolicyIcon from "@mui/icons-material/Policy";
import SecurityIcon from "@mui/icons-material/Security";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import React, {
  FunctionComponent,
  ReactNode,
  useContext,
  useMemo,
} from "react";
import { Link, matchPath, useLocation } from "react-router-dom";

import { Tooltip } from "components";

import { Scope3Context } from "pages";
import { LEGAL_PAGE_SETTINGS } from "pages/authenticated/settings/legal";
import { USER_SECURITY_PAGE } from "pages/authenticated/settings/security";
import { USER_PREFERENCES_PAGE } from "pages/authenticated/settingsv2";

import { useUserRole } from "recoils";

// import { USER_SECURITYS_PAGE } from "pages/authenticated/settingsv2";

export const SettingsOptionsList: FunctionComponent<{
  isOpen: boolean;
  icon: ReactNode;
  path: string;
  isSelected?: boolean;
  isScope3?: boolean;
}> = ({ isOpen, icon, path, isSelected, isScope3 = false }) => {
  const { isAdmin } = useUserRole();
  const options = [
    {
      label: "Preferences",
      to: USER_PREFERENCES_PAGE.ABSOLUTE_PATH,
      icon: <CheckCircleOutlineIcon 
      sx={{ marginBottom: "10px", height: "27px", width: "26px" }}
      />,
      shortLabel: "U",
      key: 0,
    },
    {
      label: "Legal",
      to: LEGAL_PAGE_SETTINGS.ABSOLUTE_PATH,
      icon: <PolicyIcon 
      sx={{ marginBottom: "10px", height: "27px", width: "26px" }} 
      />,
      shortLabel: "U",
      key: 1,
    },
    ...(isAdmin
      ? [
          {
            label: "Security",
            to: USER_SECURITY_PAGE.ABSOLUTE_PATH,
            icon: <SecurityIcon 
            sx={{ marginBottom: "10px", height: "27px", width: "26px" }}
             />,
            shortLabel: "U",
            key: 2,
          },
        ]
      : []),
  ];

  const { pathname } = useLocation();
  const selectedOption = options.filter((item) => matchPath(item.to, pathname));
  const initialTabIndex = selectedOption.length ? selectedOption[0].key : null;
  const { activeScope } = useContext(Scope3Context);
  const setDefaultOpen = () => !(isScope3 && activeScope.length > 1);
  const [open, setOpen] = React.useState(setDefaultOpen());
  const [selectedIndex, setSelectedIndex] = React.useState(initialTabIndex);

  if (selectedIndex !== initialTabIndex) {
    setSelectedIndex(initialTabIndex);
  }

  const handleListItemClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const getSideBarItem = useMemo(
    () => (
      <ListItem>
        <ListItemButton
          sx={{
            minHeight: 44,
            justifyContent: isOpen ? "initial" : "center",
            px: 2,
            borderRadius: 2,
            "&.Mui-selected": {
              backgroundColor: "background.default",
              "& svg": {
                color: "primary.main",
              },
              ":hover": {
                backgroundColor: "background.default",
              },
            },
            "&.Mui-focusVisible": {
              backgroundColor: "background.default",
            },
            ":hover": {
              backgroundColor: "transparent",
              color: isSelected ? null : "primary.main",
              "& svg": {
                color: "primary.main",
              },
            },
          }}
          selected={isSelected}
        >
          <Tooltip title="Settings">
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isOpen ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              {icon}
            </ListItemIcon>
          </Tooltip>
          <ListItemText
            primary="Settings"
            sx={{
              opacity: isOpen ? 1 : 0,
              "& .MuiListItemText-primary": {
                fontWeight: isSelected ? 700 : 500,
              },
            }}
          />
        </ListItemButton>
      </ListItem>
    ),
    [isSelected, icon, isOpen],
  );

  return (
    <>
      <ListItemButton
        sx={{ padding: "0px 0px 0px 0px" }}
        component={Link}
        to={path}
        onClick={handleClick}
      >
        {getSideBarItem}
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          sx={{
            paddingLeft: isOpen ? "20px" : "0px",
          }}
        >
          {options.map((item, index) => (
            <ListItemButton
              onClick={(event) => handleListItemClick(event, index)}
              selected={index === selectedIndex}
              component={Link}
              to={item.to}
              sx={isOpen ? { pl: 4 } : { p: 0, justifyContent: "center" }}
              key={item.label}
            >
              <Tooltip title={`Settings ${item.label as string}`}>
                <ListItemIcon sx={!isOpen ? { minWidth: 0 } : {}}>
                  {item?.icon}
                </ListItemIcon>
              </Tooltip>
              {isOpen && <ListItemText primary={item.label} />}
            </ListItemButton>
          ))}
        </List>
      </Collapse>{" "}
    </>
  );
};
