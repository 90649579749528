/* eslint-disable */
import Modal from '@mui/material/Modal';
import { Button, Grid, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { MRT_ColumnDef } from "material-react-table";
import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { Avatar, Body1Regular, Body2Regular, CloseButton, EMPTY_CELL_VALUE, Label16SemiBold, Label18SemiBold, PrimaryLabel } from "components";
import { getInitials, isNullOrUndefined } from 'utils';
import { AUDIT_LOGS_COLOR, AUDIT_LOGS_EVENT } from 'consts';
import moment from 'moment';
export const AUDIT_LOGS_COLUMNS_MAIN: MRT_ColumnDef<any>[] = [
  {
    header: "Activity Type",
    accessorKey: "eventType",
    size: 80,
    Cell: ({ cell }) => {
      const value = cell.getValue()
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }
      return (
        <Box sx={{ display: "flex", width: '70px' }}>
          <Tooltip title={value as string}>
            <Box>
              {value as any}
            </Box>
          </Tooltip>
        </Box>
      );
    },
  },
  {
    header: "Resources Affected",
    accessorKey: "className",
    size: 80,
    Cell: ({ cell }) => {
      const value = cell.getValue()
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }
      return (
        <Box sx={{ display: "flex", width: '30px' }}>
          <Tooltip title={value as string}>
            <Box>
              {value as any}
            </Box>
          </Tooltip>
        </Box>
      );
    },
  },
  {
    header: "User",
    accessorKey: "userName",
    size: 40,
    Cell: ({ cell, row }) => {
      const value = cell.getValue()
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }

      const firstName = row.original?.firstName
      const lastName = row.original?.lastName
      
      const uploaderName = firstName + " " + lastName;

      return (
        <Box sx={{ display: "flex" }}>
          <Tooltip title={value as string}>
            <Box sx={{width: "50px"}}>
            <Avatar
                sx={{
                  width: "32px",
                  height: "32px",
                }}
              >
                {getInitials(uploaderName as any)}
              </Avatar>
            </Box>
          </Tooltip>
        </Box>
      );
    },
  },
  {
    header: "TimeStamp",
    accessorKey: "createDate",
    size: 160,
    Cell: ({ cell }) => {
      const value = cell.getValue()
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }
      return (
        <Box sx={{ display: "flex", width: '30px' }}>
          <Tooltip title={value as string}>
            <Box>
              {moment(value as string).format('YYYY/MM/DD HH:mm:ss') as any}
            </Box>
          </Tooltip>
        </Box>
      );
    },
  },
  {
    header: "Change Log",
    accessorKey: "context",
    size: 340,
    Cell: ({ cell }) => {
      const value = cell.getValue()
      const context = JSON.parse(value as string)

      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }

      // First Two Data's for Table
      const keysToShow = Object.keys(context).slice(0, 2);

      // Tooltip Data
      const tooltipData = <ul>
        {Object.keys(context).map((key) => (
          <li key={key}>
            {key} : {context[key].oldValue} {"->"} {context[key].newValue}
          </li>
        ))}
      </ul>

      return (
        <Box sx={{ display: "flex", width: "150px" }}>
          <Tooltip title={tooltipData as any}>
            <div>
              {keysToShow.map((key) => (
                <div key={key}>
                  • {key} : {context[key].oldValue} {"->"} {context[key].newValue}
                </div>
              ))}
              &nbsp; {Object.keys(context).length > 2 && "... more"}
            </div>
          </Tooltip>
        </Box>
      );
    },
  },
  {
    header: "",
    accessorKey: "id",
    enableColumnActions: false,
    size: 100,
    Cell: ({ row, cell }) => {
      //** To get the value of context
      const currentData = row.original

      const value = currentData?.context
      const context = JSON.parse(value as string)

      const [open, setOpen] = useState(false);
      
      const handleClose = () => setOpen(false);

      const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        height: "80%",
        bgcolor: "background.paper",
        boxShadow: 24,
        zIndex: 999,
      };

      const ScrollableBox = styled(Box)(({ theme }) => ({
        border: '1px solid #C1C5CE', // Small grey border
        borderRadius: "8px",
        marginTop: "8px",
        maxHeight: '350px', // Height of the box
        overflowY: 'scroll', // Vertical scrollbar
        padding: theme.spacing(2), // Padding inside the box
        '&::-webkit-scrollbar': {
          marginTop: "12px",
          width: '8px', // Width of the scrollbar
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent', // Background of the scrollbar track
          borderRadius: '14px', // Rounded corners for the scrollbar thumb
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#f1f1f1', // Color of the scrollbar thumb
          borderRadius: '6px', // Rounded corners for the scrollbar thumb
        }
      }));


      const handleViewClick = async (e: any) => {
        setOpen(true);
      };

      return (
        <>
          <Box sx={{ textAlign: "right", cursor: "pointer" }}>
            <Button onClick={(e: any) => handleViewClick(e)}>
              <div>View Details</div>
            </Button>
          </Box>
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                {/* Title */}
                <div style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer', padding: "12px", marginLeft: '26px', alignItems: 'center' }}>
                  <Label16SemiBold>
                    View Details
                  </Label16SemiBold>
                  <span onClick={() => setOpen(false)}>
                    <CloseButton />
                  </span>
                </div>
                {/* Grey Header */}
                <Box sx={{ height: 80, backgroundColor: '#F6F8FA', my: '12px' }}>
                  <Grid sx={{ mx: 3 }} container spacing={2}>
                    <Grid item xs={5}>
                      <PrimaryLabel>Activity Type</PrimaryLabel>
                      <Body2Regular>{currentData?.eventType}</Body2Regular>
                    </Grid>
                    <Grid item xs={4}>
                      <PrimaryLabel>User</PrimaryLabel>
                      <Body2Regular>{currentData?.userName}</Body2Regular>
                    </Grid>
                    <Grid item xs={3}>
                      <PrimaryLabel>Date Created</PrimaryLabel>
                      <Body2Regular>{moment(currentData?.createDate as string).format('MM/DD/YYYY HH:mm:ss')}</Body2Regular>
                    </Grid>
                  </Grid>
                </Box>
                {/* Scrollable Component  */}
                <Box sx={{ m: 5, display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: '100%' }}>
                    <Body1Regular>{AUDIT_LOGS_EVENT[currentData?.eventType]}</Body1Regular>
                    <ScrollableBox>
                      <div>
                        {/* Content inside the box */}
                        {Object.keys(context).map((key) => (
                          <Box
                            sx={{
                              padding: '8px 12px',
                              gap: '10px',
                              borderRadius: '6px',
                              boxSizing: 'border-box',
                              backgroundColor: AUDIT_LOGS_COLOR[currentData?.eventType],
                              mt: 1,
                            }}
                          >
                            <li key={key}>
                              {key} : {context[key].oldValue} {context[key]?.oldValue ? "->" : ""} {context[key].newValue}
                            </li>
                          </Box>

                        ))}
                      </div>
                    </ScrollableBox>
                  </div>
                </Box>
              </Box>
            </Modal>
          </div>
        </>
      );
    },
  }
];
