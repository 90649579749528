import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import React, {
  FunctionComponent,
  ReactNode,
  useContext,
} from "react";
import { Link, matchPath, useLocation } from "react-router-dom";


import { Scope3Context } from "pages";
import {
  EMISSION_FACTORS_FILES,
  EMISSION_FACTORS_REFERENCES,
} from "pages/authenticated/emission-factors";
import { EsgArrowDown, EsgArrowRight, EsgDropLine, EsgFilesIcon, EsgReferencesIcon } from "assets";
import { Box } from "@mui/system";


export const ResourcesOptionsList: FunctionComponent<{
  isOpen: boolean;
  icon: ReactNode;
  path: string;
  isSelected?: boolean;
  isScope3?: boolean;
}> = ({ isOpen, icon, path, isSelected, isScope3 = false }) => {
  const options = [
    {
      label: "References",
      to: EMISSION_FACTORS_REFERENCES.ABSOLUTE_PATH,
      shortLabel: "U",
      icon: <EsgReferencesIcon />,
      key: 0,
    },
    {
      label: "Files",
      to: EMISSION_FACTORS_FILES.ABSOLUTE_PATH,
      shortLabel: "U",
      icon: <EsgFilesIcon />,
      key: 1,
    },

  ];

  const { pathname } = useLocation();
  const selectedOption = options.filter((item) => matchPath(item.to, pathname));
  const initialTabIndex = selectedOption.length ? selectedOption[0].key : null;
  const { activeScope } = useContext(Scope3Context);
  const setDefaultOpen = () => !(isScope3 && activeScope.length > 1);
  const [open, setOpen] = React.useState(setDefaultOpen());
  const [selectedIndex, setSelectedIndex] = React.useState(initialTabIndex);

  if (selectedIndex !== initialTabIndex) {
    setSelectedIndex(initialTabIndex);
  }

  const handleListItemClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const showPopup = Boolean(anchorEl);

  return (
    <>
      <ListItem sx={{ display: 'grid', ...(isOpen && { pl: 0 }) }}
        onClick={(e) => !isOpen && handlePopoverOpen(e)}>
        <ListItemButton
          selected={isSelected}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            borderRadius: 2,
            "&.Mui-selected": {
              backgroundColor: "background.default",
              "& svg": {
                color: "primary.main",
              },
              ":hover": {
                backgroundColor: "background.default",
              },
            },
            "&.Mui-focusVisible": {
              backgroundColor: "background.default",
            },
            ":hover": {
              backgroundColor: "transparent",
              color: isSelected ? null : "primary.main",
              "& svg": {
                color: "primary.main",
              },
            },
          }}
          component={Link}
          to={path}
          onClick={handleClick}
        >
          <ListItemIcon
            sx={{
              justifyContent: "center",
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            primary="Resources"
            sx={{
              opacity: isOpen ? 1 : 0,
              "& .MuiListItemText-primary": {
                fontWeight: isSelected ? 700 : 500,
                ":hover": {
                  backgroundColor: "transparent",
                },
              },
              ".MuiListItemButton-root": {
                ":hover": {
                  backgroundColor: "transparent",
                },

              }
            }}
          />
          {isOpen && <Box sx={{ ml: '100px' }}>
            {open ? <EsgArrowDown /> : <EsgArrowRight />}
          </Box>}
          {/* {getSideBarItem} */}
        </ListItemButton>
        {!isOpen && <span style={{ textAlign: 'center', fontSize: '11px' }}>Resources</span>}
      </ListItem>
      {isOpen && <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          sx={{
            paddingLeft: isOpen ? "20px" : "0px",
          }}
        >
          {options.map((item, index) => (
            <Box sx={{ display: 'flex', justifyContent: 'center' }} key={item.key}>
              {isOpen && <ListItemIcon sx={{
                minWidth: "12px",
                position: 'absolute',
                left: '43px',
                mt: 0,
                zIndex: 9999,
              }}>
                <EsgDropLine />
              </ListItemIcon>}
              <ListItemButton
                onClick={(event) => handleListItemClick(event, index)}
                selected={index === selectedIndex}
                component={Link}
                to={item.to}
                sx={
                  {
                    pl: isOpen ? 4 : 0,
                    height: '42px',
                    ml: 3,
                    borderRadius: 2,
                    justifyContent: !isOpen ? "center" : '',
                    "&.Mui-selected": {
                      backgroundColor: "background.default",
                      ":hover": {
                        backgroundColor: "background.default",
                      },
                    },
                    ".MuiListItemButton-root": {
                      ":hover": {
                        backgroundColor: "transparent",
                      },
                    }
                  }
                }
                key={item.label}
              >
                {isOpen &&
                  <ListItemIcon sx={!isOpen ? { minWidth: 0, pl: item.label === "Upload" ? 3 : 2, mt: 0 } : {minWidth: '40px'}}>
                    {item?.icon}
                  </ListItemIcon>}
                {isOpen && <ListItemText primary={item.label} />}
              </ListItemButton>
            </Box>
          ))}
        </List>
      </Collapse>}
      {!isOpen && <Popover
        id="mouse-over-popover"
        sx={{
          left: 80,
          position: 'absolute',
          ".MuiPopover-paper": {
            borderRadius: '12px',
          }
        }}
        open={showPopup}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {options.map((item, index) => (
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }} key={item.key}>
            <ListItemButton
              onClick={(event) => {
                handleListItemClick(event, index)
                handlePopoverClose()
              }}
              selected={index === selectedIndex}
              component={Link}
              to={item.to}
              sx={
                {
                  height: '42px',
                  borderRadius: 2,
                  justifyContent: !isOpen ? "center" : '',
                  "&.Mui-selected": {
                    backgroundColor: "background.default",
                    ":hover": {
                      backgroundColor: "background.default",
                    },
                  },
                  ".MuiListItemButton-root": {
                    ":hover": {
                      backgroundColor: "transparent",
                    },
                  }
                }
              }
              key={item.label}
            >
              <ListItemIcon sx={!isOpen ? { minWidth: 0, pl: 2, mr: 3 } : {}}>
                {item?.icon}
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItemButton>
          </Box>
        ))}
      </Popover>}
    </>
  );
};
