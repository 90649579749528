/* eslint-disable react/button-has-type */
/* eslint-disable react/destructuring-assignment */

import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Interpolation.css";
import { Viewasofdate } from "assets";
// import { useMutation, useQueryClient } from "@tanstack/react-query";
// import { createViewAsOfDate } from "api";
// import { useNotify } from "hooks";
// import { QUERY_KEYS } from "consts";

// eslint-disable-next-line react/function-component-definition
export default function AddDateModel({viewAsDate}: {viewAsDate: any}) {
    
    const [getDateButton, setgetDateButton] = useState();
    const [startDate, setStartDate] = useState(new Date());
    const [getDate, setgetDate] = useState();
    const [initialDate] = useState(new Date());
    const [datePickerOpen, setDatePickerOpen] = useState(false);

    // const { mutateAsync: createRequest } = useMutation(createViewAsOfDate);
    // const notify = useNotify();
    // const queryClient = useQueryClient();
    // eslint-disable-next-line react/no-unstable-nested-components
    const CustomTimeInput = () => {
        const handleHourChange = (e: { target: { value: string; }; }) => {
            // eslint-disable-next-line radix
            const hours = parseInt(e.target.value);
            const newDate = new Date(startDate);
            newDate.setHours(hours);
            setStartDate(newDate);
        };

        const handleMinuteChange = (e: { target: { value: string; }; }) => {
            // eslint-disable-next-line radix
            const minutes = parseInt(e.target.value);
            const newDate = new Date(startDate);
            newDate.setMinutes(minutes);
            setStartDate(newDate);
        };

        const handleAmPmChange = (e: { target: { value: any; }; }) => {
            const amPm = e.target.value;
            const newDate = new Date(startDate);
            const hours = newDate.getHours();

            newDate.setHours(hours >= 12 && amPm === "AM" ? hours - 12 : hours + 12);
            setStartDate(newDate);
        };

        return (
            <>
                <div style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center"
                }}
                >
                    <select
                        style={{
                            borderRadius: "7px",
                            marginRight: "5px"
                        }}
                        value={startDate.getHours() % 12 || 12}
                        onChange={handleHourChange}>
                        {Array.from({ length: 12 }, (_, index) => (
                            <option key={index} value={index}>
                                {index === 0 ? "12" : index}
                            </option>
                        ))}
                    </select>
                    :
                    <select
                        style={{
                            borderRadius: "7px",
                            marginLeft: "5px"
                        }}
                        value={startDate.getMinutes()}
                        onChange={handleMinuteChange}>
                        {Array.from({ length: 60 }, (_, index) => (
                            <option key={index} value={index}>
                                {index < 10 ? `0${index}` : index}
                            </option>
                        ))}
                    </select>
                    <select
                        style={{
                            borderRadius: "7px",
                            marginLeft: "5px"
                        }}
                        value={startDate.getHours() >= 12 ? "PM" : "AM"}
                        onChange={handleAmPmChange}>
                        {/* Options for AM/PM */}
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                    </select>
                </div>
                <div
                    style={{
                        borderBottom: "1px solid #E5E7EB",
                        width: "100%",
                        margin: "8px 0",
                    }}
                />
                <div style={{
                    marginTop: "10px",
                    padding: "5px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    margin: "5px"
                }}
                >
                    <div>
                        <button
                            style={{
                                cursor: "pointer",
                                height: "35px",
                                width: "75px",
                                margin: "5px",
                                borderRadius: "7px",
                                backgroundColor: "#FFFFFF",
                                color: "#1F2937",
                                border: "1px solid #E5E7EB"
                            }}
                            onClick={() => handleCancel()}>
                            Cancel
                        </button>
                        <button
                            style={{
                                cursor: "pointer",
                                height: "35px",
                                width: "75px",
                                margin: "5px",
                                borderRadius: "7px",
                                backgroundColor: "#054254",
                                color: "#FFFFFF"
                            }}
                            onClick={() => handleApply()}>
                            Apply
                        </button>
                    </div>
                </div>
            </>
        );
    };
    const handleApply = async () => {
        setStartDate(initialDate);
        setDatePickerOpen(false);
        viewAsDate(getDate)
        setgetDateButton(getDate)
        // const RequestData = {
        //     SelectedDate: getDate,
        // };
      
        // await createRequest(RequestData, {
        //     onError: () => {

        //         notify.error(`Some error has happened while View as of Date!`);
        //     },
        //     onSuccess: () => {
        //         queryClient.invalidateQueries([QUERY_KEYS.MISSING_DOCUMENTS]);

        //         notify.success(
        //             `View as of Date Succefull!`,
        //         );

        //     },
        // });

    };
    const handleCancel = () => {
        setStartDate(initialDate);
        setDatePickerOpen(false);
    };
    const handleAmPmChangeAllDate = (e: any) => {
        setStartDate(e);
    };
    // eslint-disable-next-line react/no-unstable-nested-components
    const ExampleCustomInput = forwardRef<HTMLButtonElement, { value: any }>(({ value }, ref) => {
        const buttonfun = (onClick: any) => {
            setDatePickerOpen(true)
            return (
                onClick
            )
        }
        setgetDate(value)
        return (
            <button
                style={{
                    backgroundColor: "#054254",
                    color: "#FFFFFF",
                    borderRadius: "7px",
                    fontSize: "",
                    height: "34px",
                    width: "150px",
                    cursor: "pointer"
                }}
                className="example-custom-input"
                onClick={buttonfun} ref={ref}>
                {/* {getDateButton || "View as of Date"} */}
                {getDateButton || (
                    <>
                        <Viewasofdate />
                        {"  "}
                        View as of Date
                    </>
                )}
            </button>
        )
    });

    return (
        <div
            style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
                display: "flex",
            }}>
            <DatePicker
                selected={startDate}
                onChange={(e) => handleAmPmChangeAllDate(e)}
                showTimeInput
                customTimeInput={<CustomTimeInput />}
                timeInputLabel=""
                dateFormat="MM/dd/yyyy h:mm aa"
                open={datePickerOpen}
                onCalendarClose={() => setDatePickerOpen(false)}
                customInput={<ExampleCustomInput value={undefined} />}
                onCalendarOpen={() => setDatePickerOpen(true)}
            />
        </div>
    );


}

